import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../Hooks/Helper";

const SiteBanner = () => {
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const getSiteBanner = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/banners`, {
          method: "GET",
          headers: {
            Authorization: baseUrl.token,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setBanner(resData.data.all_product_side_image);
        }
      } catch (error) {}
    };
    getSiteBanner();
  }, []);
  return (
    <div className="h-32 lg:h-64 mt-6">
      {banner ? (
        <img
          src={`${baseUrl.img}${banner}`}
          alt=""
          className="w-full h-full object-cover"
        />
      ) : (
        <img
          src={"https://placehold.co/1344x256"}
          alt=""
          className="w-full h-full object-cover"
        />
      )}
    </div>
  );
};

export default SiteBanner;
