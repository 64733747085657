import {RouterProvider} from "react-router-dom";
import {router} from "./Router/Router";
import {useEffect, useState} from "react";
import Customization from "./Components/Customization/Cutomization";
import useStore from "./Hooks/useStore";
import "./App.css";
import { getFromCookie, saveInCookie} from "./Hooks/Helper";
import Loader2 from "./Components/Loaders/Loader2/Loader2";

const App = () => {
    const {setting, setSelectedBranch} = useStore().data;
    const pixelCode = setting?.pixel_id
    window._DEFAULT_DATA = 'Data parsed in string'
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Set a global variable with the context value
        window.REACT_APP_CONTEXT_VALUE = pixelCode;
        localStorage.setItem("code", pixelCode)
        saveInCookie("code", pixelCode)
    }, [pixelCode]);

    useEffect(() => {
        window.onfocus = function () {
            document.title = setting?.name;
        };
        window.onblur = function () {
            document.title = "Come back soon";
        };
    }, [setting?.name]);


    ///APP.JS
    useEffect(() => {

        const domainName = window.location.host;
        // console.log("domainName-->", domainName)
        // const domainName = 'tailortrends.seloboard.store'
        // const domainName = 'aatnumberdokan.store'


        const getApiUrl = async () => {
            try {
                const domainCookie = document.cookie.split('; ').find(row => row.startsWith('domain_name='));
                const apiKeyCookie = document.cookie.split('; ').find(row => row.startsWith('api_key='));

                if (!domainCookie || !apiKeyCookie) { // Only make the API call if the cookies are not already set
                    const res = await fetch(`https://selopia.store/api/super-admin/check-domain/${domainName}`, {
                        method: "GET",
                    });
                    if (res.ok && res.status !== 204) {
                        const resData = await res.json();
                        // document.cookie = `domain=${resData.domain}`;
                        document.cookie = `domain_name=api.${domainName}`
                        document.cookie = `api_key=${resData.api_key}`;
                        window.location.reload();
                    }
                }
            } catch (e) {
                console.error("error",e);
            } finally {
                setLoading(false);
            }
        };
        getApiUrl().then();
    }, []);

    const branch = getFromCookie("branch");
    useEffect(() => {
        if (branch) {
            const branchName = JSON.parse(branch)
            setSelectedBranch(branchName);
        }
    }, [branch, setSelectedBranch]);

    if (loading) {
        return <Loader2/>
    }

    return (
        <>
            {
                <div className="App bg-slate-50 dark:bg-gray-950">
                    <Customization/>
                    <RouterProvider router={router}/>
                </div>
            }
        </>
    );
};

export default App;
