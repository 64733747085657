import React, {useState, useEffect, useRef} from "react";
import {baseUrl, errorNotify, successNotify} from "../../Hooks/Helper";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {IoLocationOutline, IoCallOutline} from "react-icons/io5";
import {BsEnvelope} from "react-icons/bs";
import useStore from "../../Hooks/useStore";
import BreadCumb from "../BreadCumb/BreadCumb";
import SectionHeading2 from "../SectionHeadings/SectionHeading2/SectionHeading2";
import {GoogleMap, LoadScript, Autocomplete} from '@react-google-maps/api'


const libraries = ['marker', "places"];

const defPosition = {
    lat: 23.71,
    lng: 90.39,
}

const ContactBanner = () => {
    const {setting, darkMode} = useStore().data;
    const {phone, email, address} = setting;
    // const [pickup, setPickup] = useState({});
    // const [contact, setContact] = useState([]);
    const [contactInfo, setContactInfo] = useState({});
    const [position, setPosition] = useState(null)

    // map
    const [map, setMap] = useState();

    const handleChange = (e) => {
        setContactInfo({
            ...contactInfo,
            [e.target.name]: e.target.value,
        });
    };


    const handleContact = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const name in contactInfo) {
            const element = contactInfo[name];
            formData.append(`${name}`, element);
        }
        const res = await fetch(`${baseUrl.url}/contact`, {
            method: "POST",
            headers: {
                authorization: baseUrl.token,
            },
            body: formData,
        });
        const resData = await res.json();
        if (resData.success === true) {
            successNotify(resData.message);
            e.target.reset();
        } else {
            resData?.errors?.map((err) => errorNotify(err));
        }
    };

    const data = {heading: "Contact Us", paragraph: null};

    const navigationData = [
        {
            page: "Home",
            route: "/",
        },
        {
            page: "Contact",
            route: "/contact",
        },
    ];


    // new added for map

    useEffect(() => {
        const getPickup = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/pick-up`, {
                    method: "GET",
                    headers: {
                        authorization: baseUrl.token,
                    },
                });
                if (res.ok) {
                    const resData = await res.json();
                    setPosition({lat: Number(resData?.data?.lat), lng: Number(resData?.data?.lng)})
                }
            } catch (error) {
                errorNotify(error);
            }
        };
        getPickup().then();
    }, []);



    // const position ={
    //     lat:  Number(pickup?.lat),
    //     lng:  Number(pickup?.lng),
    // }

    // const [mapCenter, setMapCenter] = useState(position);
    // const [selectedPosition, setSelectedPosition] = useState(null);
    // const autocompleteRef = useRef(null);
    // const onPlaceChanged = () => {
    //     const place = autocompleteRef.current.getPlace();
    //     if (place && place.geometry) {
    //         const location = place.geometry.location;
    //         setMapCenter({
    //             lat: location.lat(),
    //             lng: location.lng(),
    //         });
    //         setSelectedPosition({
    //             lat: location.lat(),
    //             lng: location.lng(),
    //         });
    //     }
    // };

    // // Initialize the AdvancedMarkerElement once the map and google are available
    useEffect(() => {
        if (map && window.google) {
            const advancedMarker = new google.maps.marker.AdvancedMarkerElement({
                position: position ? position : defPosition,
                map,
            });
        }
    }, [map, position]);

    const onLoad = (mapInstance: google.maps.Map) => {
        setMap(mapInstance);
    };


    return (
        <div className="pb-5">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="container pt-6 mb-3 ">
                <SectionHeading2 data={data}/>
                <BreadCumb data={navigationData}/>
            </div>

            <div className="w-full">

                <LoadScript googleMapsApiKey="AIzaSyADst8zLb8kTmCqi2UsNw_--jua1NMQoyY" libraries={libraries}>
                    <GoogleMap
                        options={{mapId: "5ed099ce2d065400"}}
                        mapContainerStyle={{
                            width: '100%',
                            height: '400px'
                        }}
                        onLoad={onLoad}
                        center={position }
                        zoom={10}
                    >

                        {/*<Autocomplete*/}
                        {/*    onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}*/}
                        {/*    onPlaceChanged={onPlaceChanged}*/}
                        {/*>*/}
                        {/*    <input*/}
                        {/*        type="text"*/}
                        {/*        placeholder="Search for a place"*/}
                        {/*        style={{*/}
                        {/*            width: '240px',*/}
                        {/*            height: '40px',*/}
                        {/*            padding: '10px',*/}
                        {/*            borderRadius: '5px',*/}
                        {/*            position: 'absolute',*/}
                        {/*            top: '10px',*/}
                        {/*            left: '50%',*/}
                        {/*            transform: 'translateX(-50%)',*/}
                        {/*            zIndex: 5,*/}
                        {/*            border: '1px solid #ccc',*/}
                        {/*            outline: 'none',*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Autocomplete>*/}
                    </GoogleMap>
                </LoadScript>
            </div>

            {/*<div>*/}
            {/*  {pickup?.lat && pickup?.lng ? (*/}
            {/*    <iFrame*/}
            {/*      className={`w-full rounded-lg shadow ${darkMode ? "dark" : ""}`}*/}
            {/*      style={{ height: "70vh" }}*/}
            {/*      loading="lazy"*/}
            {/*      allowFullScreen*/}
            {/*      referrerPolicy="no-referrer-when-downgrade"*/}
            {/*      src={`https://www.google.com/maps/embed/v1/place?q=${pickup?.lat},${pickup?.lng}&key=AIzaSyAHqxlh7rQzVcqZkKAq69Vhrksa5L2_jQg`}*/}
            {/*    ></iFrame>*/}
            {/*  ) : (*/}
            {/*    <div>*/}
            {/*      <div>*/}
            {/*        <img*/}
            {/*          src="https://e7.pngegg.com/pngimages/160/350/png-clipart-google-my-business-local-search-google-maps-business-search-engine-optimization-people.png"*/}
            {/*          className="w-3/4 lg:w-1/4 mx-auto"*/}
            {/*          alt=""*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <h1 className="text-xl text-center dark:text-white">*/}
            {/*        No location found*/}
            {/*      </h1>*/}
            {/*      <p className="text-base text-center dark:text-white">*/}
            {/*        No coordinates added or found nothing*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}


            <div className="container pt-6 ">
                <div className="mt-3">
                    <div className="grid grid-cols-12 gap-4 lg:gap-8 xl:gap-4 mt-5 lg:mt-8">
                        <div className="col-span-12 lg:col-span-4 h-full">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 items-center gap-3">
                                <div className="shadow-none lg:shadow p-0 lg:p-2 rounded mb-2 flex items-center gap-4">
                                    <div className="flex justify-center">
                                        <div className="p-4 rounded-full bg-blue-100">
                                            <IoLocationOutline className="text-blue-600" size={25}/>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <p className="font-medium text-slate-700 dark:text-white">
                                            Our location
                                        </p>
                                        <h6 className="-mt-1 text-gray-500 m-0 font-medium ">
                                            {address}
                                        </h6>
                                    </div>
                                </div>

                                <div
                                    className="shadow-none lg:shadow p-0 lg:p-2 text-center mb-2 flex items-center gap-4">
                                    <div className="flex justify-center">
                                        <div className="p-4 rounded-full bg-purple-100">
                                            <IoCallOutline className="text-purple-600" size={25}/>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <p className="font-medium dark:text-white">Phone Number</p>
                                        <h6 className="-mt-1 text-gray-500 font3 font-medium">
                                            {phone}
                                        </h6>
                                    </div>
                                </div>

                                <div className="shadow-none lg:shadow p-0 lg:p-2 text-center flex items-center gap-4">
                                    <div className="flex justify-center">
                                        <div className="p-4 rounded-full bg-pink-100">
                                            <BsEnvelope className="text-pink-600" size={25}/>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <p className="font-medium dark:text-white">Email Address</p>
                                        <h6 className="-mt-1 text-gray-500 font3 m-0 font-medium">
                                            {email}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-8 shadow rounded-sm p-3 lg:p-5">
                            <div className="mb-3">
                                <h3 className="text-2xl tracking-wide font-medium text-blue-500 dark:text-white">
                                    Get in touch
                                </h3>
                                <p className="text-sm text-slate-500 dark:text-white">
                                    We are eagerly waiting for your response...
                                </p>
                            </div>
                            <form onSubmit={handleContact} className="mt-8">
                                <div className="sm:block lg:flex items-center gap-3">
                                    <div className="w-full">
                                        <label className="block  mb-1 text-slate-600 text-sm dark:text-white">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full bg-slate-100 dark:bg-slate-700 focus:outline-none rounded-sm p-3 focus:border-blue-400"
                                            name="first_name"
                                            onChange={handleChange}
                                            placeholder="first name"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <label className="block mb-1 text-slate-600 text-sm dark:text-white">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full focus:outline-none rounded-sm p-3 focus:border-blue-400 bg-slate-100 dark:bg-slate-700"
                                            name="last_name"
                                            onChange={handleChange}
                                            placeholder="last name"
                                        />
                                    </div>
                                </div>
                                <div className="w-full my-2">
                                    <label className="block mb-1 text-slate-600 text-sm dark:text-white">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className="w-full focus:outline-none rounded-sm p-3 focus:border-blue-400 bg-slate-100 dark:bg-slate-700"
                                        name="email"
                                        onChange={handleChange}
                                        placeholder="email"
                                    />
                                </div>
                                <div className="w-full my-2">
                                    <label className="block mb-1 text-slate-600 text-sm dark:text-white">
                                        Phone Number
                                    </label>
                                    <input
                                        type="number"
                                        className="w-full focus:outline-none rounded-sm p-3 focus:border-blue-400 bg-slate-100 dark:bg-slate-700"
                                        name="phone"
                                        onChange={handleChange}
                                        placeholder="phone"
                                    />
                                </div>
                                <div className="w-full">
                                    <label className="block mb-1 text-slate-600 text-sm dark:text-white">
                                        Message
                                    </label>
                                    <textarea
                                        onChange={handleChange}
                                        placeholder="Start form here"
                                        name="message"
                                        className="w-full focus:outline-none rounded-sm p-3 focus:border-blue-400 bg-slate-100 dark:bg-slate-700"
                                        id=""
                                        rows="4"
                                    ></textarea>
                                </div>
                                <div className="my-2 flex justify-start">
                                    <button
                                        type="submit"
                                        className="bg-red-400 rounded px-4 py-2 dark:text-black text-black"
                                    >
                                        Send Message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactBanner;
