import React, {useState, useEffect} from "react";
import {BsCashCoin, BsSuitHeart} from "react-icons/bs";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {MdReportGmailerrorred, MdShare} from "react-icons/md";
import {
    AiFillStar,
    AiOutlineEye,
    AiOutlineMinus,
    AiOutlinePlus,
    AiOutlineStar,
} from "react-icons/ai";
import {IoReloadCircleSharp} from "react-icons/io5";
import {RiFolderAddLine, RiStockLine} from "react-icons/ri";
import {CiDeliveryTruck} from "react-icons/ci";
import {BsStarHalf} from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import useStore from "../../Hooks/useStore";
import {
    b64ToPng,
    baseUrl,
    errorNotify, saveInCookie,
    successNotify,
} from "../../Hooks/Helper";
import NoContent from "../../Pages/NoContent/NoContent";
import {TbArrowBigLeftLinesFilled, TbArrowsShuffle} from "react-icons/tb";
import {BiSupport} from "react-icons/bi";
import "rodal/lib/rodal.css";
import {useParams} from "react-router-dom";
import {fetchAllwishList} from "../../Redux/Slices/Wishlist/wishlistSlice";
import {useDispatch, useSelector} from "react-redux";
import {TfiEmail} from "react-icons/tfi";
import {ShareSocial} from "react-share-social";
import ReactImageMagnify from "react-image-magnify";
import Button from "../Button/Button";
import {ToastContainer} from "react-toastify";


const ProductView = () => {

    //wishlist items
    const {wishlist} = useSelector((state) => state?.wishlist);
    const [visibleReport, setVisibleReport] = useState(false);
    const [visibleShare, setVisibleShare] = useState(false);
    const dispatch = useDispatch();
    const {id} = useParams();
    const [callCaptcha, setCallCaptcha] = useState(false);
    const [showExit, setShowExit] = useState(wishlist?.length > 0);
    const [selectedWishList, setSelectedWishList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [flashSale, SetFlashSale] = useState({});

    const [captcha, setCaptcha] = useState(null);
    const [captchaReload, setCaptchaReload] = useState(false);
    const [count, setCount] = useState(1);
    const [view, setView] = useState(null);
    const [reportData, setReportData] = useState({});

    //ADD TO WISHLIST AND GET WISHLIST
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({});

    const [visibleBranch, setVisibleBranch] = useState(false);

    const {
        productDetails,
        customerToken,
        setProductDetails,
        compare,
        setCompare,
        setUpdate,
        update,
        customerInfo,
        branchList,
        selectedBranch,
        setSelectedBranch
    } = useStore().data;


    const handleReportData = (e) => {
        setReportData({
            ...reportData,
            [e.target.name]: e.target.value,
        });
    };

    const handleReport = async (e) => {
        e.preventDefault();
        reportData.key = captcha?.key;
        reportData.product_id = productDetails?.id;
        let url = `${baseUrl.url}`;
        if (customerToken?.length > 5) {
            url = url + "/user/product-abuse-report";
        } else {
            url = `${baseUrl.url}/product-abuse-report`;
        }
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: customerToken
                    ? `bearer ${customerToken}`
                    : baseUrl.token,
            },
            body: JSON.stringify(reportData),
        });
        if (res.ok) {
            successNotify("Report added for this product");
            setVisibleReport(false);
            setCallCaptcha(false);
            setCaptcha(null);
        }
    };

    const handleRestock = async () => {
        const postData = {
            product_id: productDetails?.id,
        };
        try {
            const res = await fetch(`${baseUrl.url}/user/product-request-restock`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${customerToken}`,
                },
                body: JSON.stringify(postData),
            });
            const resData = await res.json();
            if (res.ok) {
                successNotify("Your Restock request is submitted successfully");
            } else {
                resData.errors.map((err) => errorNotify(err));
            }
        } catch (e) {
            console.error(e)
        }
    };

    const verified = customerInfo?.user_data?.email_verified_at;

    useEffect(() => {
        if (!customerToken || !verified) {
            return;
        }
        if (customerToken) {
            dispatch(fetchAllwishList({customerToken}));
        }
    }, [dispatch, customerToken, update, verified]);

    useEffect(() => {
        const filteredArray = wishlist.map((obj) => {
            const ss = obj?.items?.map(
                (item) =>
                    item?.product_combination_id ===
                    productDetails?.default_combination[0]?.id
            );
            if (ss.includes(true)) {
                return {
                    ...obj,
                    check: true,
                };
            }
            return {
                ...obj,
                check: false,
            };
        });
        setSelectedWishList(filteredArray);
    }, [wishlist, productDetails?.default_combination]);


    // product add in cart
    const addToCart = async () => {
        if (productDetails?.inventories_sum_stock_quantity < 1) {
            errorNotify("Product quantity is not available for these items");
            return;
        }
        if (count > productDetails?.inventories_sum_stock_quantity) {
            errorNotify("You can't order more than the available stock quantity");
            return;
        }
        const formData = new FormData();
        formData.append("quantity", count);
        formData.append(
            "product_combination_id",
            productDetails?.default_combination[0]?.id
        );

        let url = `${baseUrl.url}/cart-store`;

        if (customerToken) {
            url = `${baseUrl.url}/user/cart-store`;
        }
        const res = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                Authorization: customerToken
                    ? `bearer ${customerToken}`
                    : baseUrl.token,
            },
            body: formData,
        });
        const resData = await res.json();
        if (resData.status) {
            setUpdate(!update);
            successNotify("Successfully added to cart");
        } else {
            resData?.errors.map((err) => errorNotify(err));
        }
    };

    useEffect(() => {
        if (!callCaptcha) {
            return;
        }
        const captchaGet = async () => {
            const res = await fetch(`${baseUrl.url}/captcha`, {
                method: "GET",
                headers: {
                    Authorization: baseUrl.token,
                },
            });
            const resData = await res.json();
            if (res.ok && res.status !== 204) {
                const img = b64ToPng(resData.captcha.img);
                setCaptcha({img: img, key: resData.captcha.key});
            }
        };
        captchaGet().then();
    }, [callCaptcha, captchaReload]);
    const increment = () => {
        setCount(count + 1);
    };
    const decrement = () => {
        if (count > 2) {
            setCount(count - 1);
        }
    };


    useEffect(() => {
        const getProductDetails = async () => {
            setLoader(true);
            try {
                const res = await fetch(`${baseUrl.url}/product-details/${id}`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });
                const resData = await res.json();
                if (res.ok && res.status !== 204) {
                    setProductDetails(resData?.data);
                    SetFlashSale(resData?.flash_sale);
                    setSelectedIds(resData?.data?.default_combination[0]?.attribute_values);
                    setView(null);
                }
            } catch (e) {
                console.error(e)
            } finally {
                setLoader(false);
            }
        };
        getProductDetails().then();
    }, [id, setProductDetails]);

    // const flashSaleDiscount = flashSale?.discount ? flashSale?.discount / 100 : 0;
    const productPrice = +productDetails?.default_combination[0]?.selling_price;
    const flashSaleActive = productDetails?.is_on_sale === 1 && flashSale;

    let display_price = flashSaleActive
        ? productPrice - (productPrice * flashSale?.discount) / 100
        : productPrice;

    // const discountedPrice = productPrice * (1 - flashSaleDiscount);

    const variantPrice = (ids) => {
        const matchedData = productDetails.product_combinations.find((dataItem) => {
            return (
                dataItem.attribute_values.length === ids.length &&
                dataItem.attribute_values.every((variant) => {
                    return ids?.some((v) => v.id === variant.id);
                })
            );
        });
        if (matchedData === undefined) {
            errorNotify("This variant is not available right now");
            return;
        }
        setProductDetails({
            ...productDetails,
            default_combination: [matchedData],
        });
        return matchedData;
    };


    // const [createList, setCreateList] = useState(false);
    const changeText = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const addToWishList = async () => {
        if (!customerToken.length) {
            return
        }
        const postData = {
            product_combination_id: productDetails?.default_combination[0]?.id,
            title: data.title,
            description: data.des,
        };
        const res = await fetch(`${baseUrl.url}/user/wish-store`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: `bearer ${customerToken}`,
            },
            body: JSON.stringify(postData),
        });
        const resData = await res.json();
        if (res.ok) {
            setUpdate(!update);
            setVisible(!visible);
            setShowExit(true)
            successNotify("Product added successfully your wishlist");
        } else {
            resData.errors.map((err) => errorNotify(err));
        }

    };

    const addToExistsWishList = async () => {
        if (!customerToken.length) {
            return
        }

        const selectWishes = selectedWishList?.filter(
            (wish) => wish?.check === true
        );
        const wishIds = selectWishes?.map((wish) => wish?.id);
        if (wishIds?.length === 0) {
            return errorNotify("Please select at least one wishlist");
        }
        const postData = {
            product_combination_id: productDetails?.default_combination[0]?.id,
            wishlist: wishIds.length > 0 && wishIds,
        };
        try {
            const res = await fetch(`${baseUrl.url}/user/wish-store`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `bearer ${customerToken}`,
                },
                body: JSON.stringify(postData),
            });
            const resData = await res.json();
            if (res.ok) {
                setUpdate(!update);
                setVisible(!visible);
                successNotify("Product added successfully your previous wishlists");
            } else {
                resData.errors.map((err) => errorNotify(err));
            }
        } catch (e) {
            console.error(e)
        }
    };

    //compares
    const checkAvailability = (arr, val) => {
        return arr?.some(function (arrVal) {
            return val === arrVal.id;
        });
    };

    const handleAddToCompare = () => {
        const item = {
            id: id,
            name: productDetails?.name,
            price: display_price,
            thumbnail: productDetails?.thumbnail_image,
            description: productDetails?.short_description,
            sold_count: productDetails?.sold_count,
            quantity: count,
            product_combination_id: productDetails?.default_combination[0]?.id,
        };

        if (compare.length < 4) {
            const isItemInArray = checkAvailability(compare, id);
            if (compare.length < 1) {
                const newCompare = [...compare, item];
                setCompare(newCompare);
            } else {
                if (isItemInArray === false) {
                    const newCompare = [...compare, item];
                    setCompare(newCompare);
                    successNotify("Product added to compare list.");
                }
            }
        } else {
            errorNotify("you can not compare more than 4 products");
        }
    };

    const handleProductCheckboxChange = (wishId, checked) => {
        const newArray = selectedWishList?.map((wish) => {
            if (wish?.id === wishId) {
                if (checked) {
                    return {
                        ...wish,
                        check: true,
                    };
                }
                return {
                    ...wish,
                    check: false,
                };
            }
            return wish;
        });
        setSelectedWishList(newArray);
    }

    const getCheckProductAvailability = async (branchId) => {
        const combinationId = productDetails?.default_combination[0]?.id;
        if (!branchId) {
            return errorNotify("Please select a branch")
        }

        if (productDetails?.inventories_sum_stock_quantity < 1) {

            return errorNotify("Product quantity is not available for these items");
        }
        if (count > productDetails?.inventories_sum_stock_quantity) {
            return errorNotify("You can't order more than the available stock quantity");
        }
        try {
            const res = await fetch(`${baseUrl.url}/check-product-availability?product_combination_id=${combinationId}&shop_branch_id=${branchId}&stock_quantity=${count}`, {
                method: "GET",
                headers: {
                    Authorization: baseUrl.token,
                },
            });
            if (res.ok && res.status !== 204) {
                setVisibleBranch(false)
            } else {
                const error = await res.json()
                errorNotify(error.errors[0]);

            }
        } catch (e) {
            console.error("error",e);
        } finally {

        }
    };

    const innerWidth = window?.innerWidth;

    return (
        <div className="my-4 p-4 bg-white dark:bg-gray-800">
            <Rodal
                visible={visibleReport}
                onClose={() => {
                    setVisibleReport(!visibleReport);
                    setCaptcha(null);
                    setCallCaptcha(false);
                }}
                animation={"fade"}
                customStyles={{
                    minWidth: "350px",
                    height: "auto",
                    width: "25%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div>
                    <h1 className="mb-3 text-xl flex items-center border-b pb-2">
                        <MdReportGmailerrorred size={25}/>
                        Report for this product
                    </h1>
                    <form onSubmit={handleReport}>
                        <div className="grid grid-cols-2 gap-4 mb-3">
                            <div>
                                <label className="text-sm " htmlFor="">
                                    Email
                                </label>
                                <input
                                    onChange={handleReportData}
                                    name="email"
                                    className="w-full focus:outline-none bg-gray-200 p-2 rounded"
                                    placeholder="type email here"
                                    type="email"
                                />
                            </div>
                            <div>
                                <label className="text-sm " htmlFor="">
                                    Phone
                                </label>
                                <input
                                    onChange={handleReportData}
                                    name="phone_no"
                                    className="w-full focus:outline-none bg-gray-200 p-2 rounded"
                                    placeholder="type phone here"
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="text-sm " htmlFor="">
                                Complain notes
                            </label>
                            <textarea
                                onChange={handleReportData}
                                name="complaint_notes"
                                placeholder="Start from here..."
                                id=""
                                className="w-full focus:outline-none bg-gray-200 p-2 rounded"
                                rows="5"
                            ></textarea>
                        </div>
                        <div className="grid grid-cols-12 w-36">
                            <div className="col-span-11">
                                <img className="h-12 w-full" src={captcha?.img} alt=""/>
                            </div>
                            <div className="col-span-1">
                                <IoReloadCircleSharp
                                    onClick={() => setCaptchaReload(!captchaReload)}
                                    className="text-green-500 cursor-pointer"
                                    size={22}
                                />
                            </div>
                        </div>
                        <div className="flex gap-1 items-center ">
                            <input
                                type="text"
                                onChange={handleReportData}
                                name="captcha"
                                placeholder="captcha code"
                                className="border focus:outline-none w-40 mt-2 p-[7px]"
                            />
                            {reportData?.captcha?.length > 4 && (
                                <Button classes={"p-1 mt-2"} size={"md"} title={"Submit"}/>
                            )}
                        </div>
                    </form>
                </div>
            </Rodal>


            <Rodal
                visible={visibleBranch}
                onClose={() => {
                    setVisibleBranch(!visibleBranch);
                }}
                animation={"fade"}
                customStyles={{
                    height: "auto",
                    width: innerWidth > 1024 ? "35%" : "90%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div>
                    <h1 className="mb-3 text-xl flex items-center border-b pb-2">
                        Choose Your Nearest Branch
                    </h1>

                    <div className="grid grid-cols-2 gap-2">

                        {
                            branchList.map((branch, i) => {
                                return (
                                    <div key={i} onClick={() => {
                                        setSelectedBranch(branch)
                                        saveInCookie("branch", JSON.stringify({label: branch?.name, value: branch?.id}))
                                        getCheckProductAvailability(branch?.id).then()
                                        addToCart().then()
                                    }}
                                         className={` border cursor-pointer rounded p-2 hover:bg-black hover:text-white duration-300`}>
                                        <h5>{branch?.name}</h5>
                                        <p className="text-sm pt-1">{branch?.address}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Rodal>


            <Rodal
                visible={visible}
                onClose={() => setVisible(!visible)}
                animation={"fade"}
                customStyles={{
                    minWidth: "400px",
                    height: "auto",
                    width: "30%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div className="rodal-width text-slate-800 p-3">
                    <div className="mb-3">
                        {wishlist.length && showExit ? (
                            <p className="text-2xl font-medium">Save to wishlist</p>
                        ) : (
                            <p className="text-2xl font-medium">Create your wishlist</p>
                        )}
                    </div>
                    {
                        wishlist?.length > 0 && <ul
                            className="flex list-none flex-row flex-wrap border-b-0 ps-0 ">

                            <li onClick={() => setShowExit(true)}
                                className={`py-2 pe-2 text-md font-medium ${showExit ? "text-red-400" : "text-gray-700"}`}>
                                Exiting Wishlist
                            </li>


                            <li onClick={() => setShowExit(false)}
                                className={`p-2 text-md font-medium ${!showExit ? "text-red-400" : "text-gray-700"}`}>
                                New Wishlist
                            </li>
                        </ul>
                    }

                    {
                        !showExit ?
                            <div>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-12 ">
                                        <div className="mb-3">
                                            <label htmlFor="">Title</label>
                                            <input
                                                type="text"
                                                name="title"
                                                onChange={(e) => changeText(e)}
                                                className="border w-full primary-text-color form-control bg-transparent focus:outline-none focus:border-blue-300 p-2 rounded-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-12 ">
                                        <div>
                                            <label htmlFor="">Description</label>
                                            <textarea
                                                type="text"
                                                name="des"
                                                onChange={(e) => changeText(e)}
                                                className="border w-full rounded-sm p-2 focus:outline-none focus:border-blue-300 primary-text-color form-control bg-transparent"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className="py-2 px-2 mt-5 bg-red-400 text-sm text-white border rounded"
                                    onClick={() => {
                                        addToWishList().then()
                                    }}
                                > Add Wishlist
                                </button>
                            </div>
                            : <>
                                {
                                    wishlist?.length > 0 && <div>
                                        {selectedWishList?.length > 0 &&
                                            selectedWishList?.map((wish, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div className="flex gap-1">
                                                            <input
                                                                type="checkbox"
                                                                checked={wish?.check}
                                                                onChange={(e) => {
                                                                    handleProductCheckboxChange(
                                                                        wish?.id,
                                                                        e.target.checked
                                                                    );
                                                                }}
                                                                className="cursor-pointer"
                                                            />
                                                            <span>{wish?.title}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                        <div className="mt-5">
                                            <button
                                                className="py-2 px-2 rounded bg-red-400 text-sm text-white border flex gap-2 items-center"
                                                onClick={addToExistsWishList}
                                            >
                                                <RiFolderAddLine size={20}/>
                                                Add to exiting wishlist
                                            </button>
                                        </div>
                                    </div>
                                }
                            </>

                    }
                    {/*<div className="flex items-center gap-2 ">*/}
                    {/*    <div>*/}
                    {/*        <button*/}
                    {/*            className="py-1 px-2  bg-red-400 text-sm text-white border flex gap-2 items-center"*/}
                    {/*            onClick={addToExistsWishList}*/}
                    {/*        >*/}
                    {/*            <RiFolderAddLine size={20}/>*/}
                    {/*            Add to exiting wishlist*/}
                    {/*        </button>*/}
                    {/*        <button*/}
                    {/*            className={`py-1 px-2  bg-red-400 text-sm text-white border   items-center flex gap-2`}*/}
                    {/*            onClick={() => {*/}
                    {/*                setCreateList(!createList);*/}
                    {/*                setShowExit(!showExit);*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <IoCreateOutline size={20}/>*/}
                    {/*            Create new wishlist*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*{createList && (*/}
                    {/*    <>*/}
                    {/*        <div className="grid grid-cols-12">*/}
                    {/*            <div className="col-span-12 ">*/}
                    {/*                <div className="mb-3">*/}
                    {/*                    <label htmlFor="">Title</label>*/}
                    {/*                    <input*/}
                    {/*                        type="text"*/}
                    {/*                        name="title"*/}
                    {/*                        onChange={(e) => changeText(e)}*/}
                    {/*                        className="border w-full primary-text-color form-control bg-transparent focus:outline-none focus:border-blue-300 p-2 rounded-sm"*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-span-12 ">*/}
                    {/*                <div className="mb-3">*/}
                    {/*                    <label htmlFor="">Description</label>*/}
                    {/*                    <textarea*/}
                    {/*                        type="text"*/}
                    {/*                        name="des"*/}
                    {/*                        onChange={(e) => changeText(e)}*/}
                    {/*                        className="border w-full rounded-sm p-2 focus:outline-none focus:border-blue-300 primary-text-color form-control bg-transparent"*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <button*/}
                    {/*            className="py-1 px-2 mt-2 bg-red-400 text-sm text-white border"*/}
                    {/*            onClick={() => {*/}
                    {/*                addToWishList();*/}
                    {/*                setCreateList(false);*/}
                    {/*                setShowExit(!showExit);*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            Add Wishlist*/}
                    {/*        </button>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>
            </Rodal>
            <Rodal
                visible={visibleShare}
                onClose={() => {
                    setVisibleShare(!visibleShare);
                }}
                animation={"fade"}
                customStyles={{
                    minWidth: "350px",
                    height: "auto",
                    width: "25%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div className="p-2">
                    <h1 className="text-lg flex items-center">
                        Share this products into your social media
                    </h1>
                    <ShareSocial
                        //  title={'Share This amazi'}
                        className="mb-5"
                        url={`${baseUrl.domain}/details/${id}/${productDetails?.slug}`}
                        socialTypes={[
                            "facebook",
                            "whatsapp",
                            "email",
                            "linkedin",
                            "telegram",
                            "pinterest",
                        ]}
                    />
                </div>
            </Rodal>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="grid grid-cols-12 rounded-lg">
                <div
                    className={`col-span-12 md:col-span-12 lg:col-span-8 xl:col-span-9`}
                >
                    {loader ? (
                        <div className="h-[500px] w-full flex items-center justify-center p-5 text-center">
                            <div className="w-12 h-12">
                                <img
                                    src="https://i.gifer.com/ZKZg.gif"
                                    alt=""
                                    className="w-full h-full"
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            {productDetails?.name ? (
                                <div className="grid grid-cols-12">
                                    <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                                        <div className="p-2 p-lg-0">
                                            <figure className="lg:relative">
                                                {productDetails?.thumbnail_image ? (
                                                    <div className={``}>
                                                        <ReactImageMagnify
                                                            {...{
                                                                smallImage: {
                                                                    alt: "product images",
                                                                    isFluidWidth: true,
                                                                    // width: 500,
                                                                    // height: 1200,
                                                                    src: `${baseUrl.img}${
                                                                        view || productDetails?.thumbnail_image
                                                                    }`,
                                                                    sizes:
                                                                        "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                                                                },
                                                                largeImage: {
                                                                    src: `${baseUrl.img}${
                                                                        view || productDetails?.thumbnail_image
                                                                    }`,
                                                                    width: 700,
                                                                    height: 700,
                                                                    offsetX: 0,
                                                                    offsetY: 0,
                                                                },
                                                                isHintEnabled: true,
                                                                lensStyle: {
                                                                    backgroundColor: "rgba(0,0,0,.6)",
                                                                },
                                                                // enlargedImagePosition: 'beside',
                                                                shouldUsePositiveSpaceLens: false,
                                                                shouldHideHintAfterFirstActivation: false,
                                                                // enlargedImageContainerDimensions: {
                                                                //   width: "100%",
                                                                //   height: "100%",
                                                                // },
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img
                                                            src={require("../../assets/gifs/product-details.gif")}
                                                            alt=""
                                                            className="w-full h-full object-cover"
                                                        />
                                                    </div>
                                                )}
                                                <div className="mt-4">
                                                    <div className="grid grid-cols-6 gap-2">
                                                        {view !== null && (
                                                            <div>
                                                                <div
                                                                    className="bg-slate-100 dark:bg-slate-700 rounded-lg cursor-pointer"
                                                                    onClick={() =>
                                                                        setView(productDetails?.thumbnail_image)
                                                                    }
                                                                >
                                                                    <img
                                                                        src={`${baseUrl.img}${productDetails?.thumbnail_image}`}
                                                                        className="w-full"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {productDetails?.product_images
                                                            ?.slice(0, 4)
                                                            .map((img, i) => (
                                                                <div key={i}>
                                                                    <div
                                                                        className="bg-slate-100 dark:bg-slate-700 rounded-lg cursor-pointer"
                                                                        onClick={() => setView(img?.image)}
                                                                    >
                                                                        <img
                                                                            src={`${baseUrl.img}${img?.image}`}
                                                                            className="w-full"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                    <div
                                        className={`col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6`}
                                    >
                                        <div className="p-4">
                                            <div>
                                                <h5 className="text-2xl font-medium text-slate-800 dark:text-white">
                                                    {productDetails?.name}
                                                </h5>
                                                <div className="mt-2 block lg:flex items-center">
                                                    <p className="mr-3 text-sm dark:text-white">
                                                        <span className="text-gray-900 dark:text-gray-400">
                                                          Category
                                                        </span>{" "}
                                                        -{" "}
                                                        <span className="font-bold">
                                                            {productDetails?.category?.name}
                                                        </span>
                                                    </p>
                                                    <p className="mr-3 text-sm dark:text-white">
                                                        <span className="text-gray-900 dark:text-gray-400">
                                                          Brand
                                                        </span>{" "}
                                                        -{" "}
                                                        <span className="font-bold">
                                                        {productDetails?.brand?.name || "No brand"}
                                                        </span>
                                                    </p>
                                                    <p className="mr-3 text-sm dark:text-white">
                                                        <span className="text-gray-900 dark:text-gray-400">
                                                          Total sold
                                                        </span>{" "}
                                                        -{" "}
                                                        <span className="font-bold">
                                                        {productDetails?.sold_count} piece
                                                        </span>
                                                    </p>
                                                </div>
                                                {/* <span className="flex items-center mb-2">
                          {Array.from({ length: 5 }).map((_, i) => {
                            if (
                              i <=
                              parseInt(
                                productDetails?.product_review_rating[0]
                                  ?.avg_review_rating
                              )
                            ) {
                              return (
                                <AiFillStar
                                  key={i}
                                  className="text-yellow-500"
                                />
                              );
                            }
                            return (
                              <AiOutlineStar
                                key={i}
                                className="text-gray-400"
                              />
                            );
                          })}
                          <span className="flex items-center ml-2">
                            <AiOutlineEye
                              className="text-red-400"
                              size={17}
                            />
                            <span className="dark:text-white text-sm">
                              {productDetails?.view_count}
                            </span>
                          </span>
                        </span> */}
                                                <div className="flex items-center mb-2">
                                                    {Array.from({length: 5}).map((_, i) => {
                                                        const averageRating = parseFloat(
                                                            productDetails?.product_review_rating[0]
                                                                ?.avg_review_rating
                                                        );

                                                        if (i < Math.floor(averageRating)) {
                                                            return (
                                                                <AiFillStar
                                                                    key={i}
                                                                    className="text-yellow-500"
                                                                />
                                                            );
                                                        } else if (
                                                            i === Math.floor(averageRating) &&
                                                            averageRating % 1 !== 0
                                                        ) {
                                                            return (
                                                                <BsStarHalf
                                                                    key={i}
                                                                    className="text-yellow-500"
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <AiOutlineStar
                                                                    key={i}
                                                                    className="text-gray-400"
                                                                />
                                                            );
                                                        }
                                                    })}
                                                    <p className="flex items-center ml-2">
                                                        <AiOutlineEye className="text-red-400" size={17}/>
                                                        <span className="dark:text-white text-sm">
                                                            {productDetails?.view_count}
                                                        </span>
                                                    </p>
                                                </div>

                                                <div className="pt-1">
                                                    <p className="text-slate-700 text-sm dark:text-slate-100">
                                                        {productDetails?.short_description}
                                                    </p>
                                                </div>
                                                <div className="flex items-center">
                                                    {flashSale ? (
                                                        <h3 className="text-2xl font-semibold text-slate-700 dark:text-white my-2">
                                                            ৳ {(count * display_price).toFixed(2)}
                                                        </h3>
                                                    ) : (
                                                        <h3 className="text-2xl font-semibold text-slate-700 dark:text-white my-2">
                                                            ৳{" "}
                                                            {(
                                                                +productDetails?.default_combination[0]
                                                                    ?.selling_price * count
                                                            ).toFixed(2)}
                                                        </h3>
                                                    )}

                                                    {productDetails?.previous_display_price && (
                                                        <del className="ms-3 text-slate-700">
                                                          <span className="text-slate-700 dark:text-white">
                                                            ৳ {productDetails?.previous_display_price}
                                                          </span>
                                                        </del>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="block lg:flex items-center">
                                                <div className="flex items-center mb-3">
                                                    <button
                                                        onClick={decrement}
                                                        className="h-8 w-8  text-white bg-red-400 flex items-center justify-center"
                                                    >
                                                        <AiOutlineMinus/>
                                                    </button>
                                                    <input
                                                        type="button"
                                                        className="h-8 w-8 dark:text-white"
                                                        value={count}
                                                    />
                                                    <button
                                                        onClick={increment}
                                                        className="h-8 w-8  text-white bg-red-400 flex items-center justify-center"
                                                    >
                                                        <AiOutlinePlus/>
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                className="bg-red-400 text-white py-2 px-3 text-sm"
                                                // onClick={addToCart}
                                                onClick={() => {
                                                    if (!selectedBranch?.id) {
                                                        setVisibleBranch(true)
                                                        // checkProductAvailability(selectedBranch?.id).then()
                                                        // addToCart().then()
                                                    } else if (selectedBranch?.id) {
                                                        getCheckProductAvailability(selectedBranch?.id).then()
                                                        addToCart().then()
                                                    }
                                                }}
                                            >
                                                Add to Cart
                                            </button>
                                            {/* <Button  size={"sm"} title={"Add to Cart"}/> */}
                                            <div className="mt-4 flex items-center">
                                                <button
                                                    className="flex items-center text-sm font-bold dark:text-gray-300"
                                                    onClick={handleAddToCompare}
                                                >
                                                    <TbArrowsShuffle size={21} className="mr-1"/> Compare
                                                </button>
                                                {customerToken.length > 0 && (
                                                    <button
                                                        className="flex items-center text-sm ml-4 font-bold dark:text-gray-300"
                                                        onClick={() => {
                                                            setVisible(!visible);
                                                        }}
                                                    >
                                                        <BsSuitHeart size={17} className="mr-1"/> Wishlist
                                                    </button>
                                                )}
                                            </div>
                                            <div className="flex items-center  gap-1 rounded mt-2">
                                                <button
                                                    onClick={() => {
                                                        setVisibleReport(true);
                                                        setCallCaptcha(true);
                                                    }}
                                                    className="flex items-center mr-5 text-xs text-gray-800 dark:text-gray-200"
                                                >
                                                    <MdReportGmailerrorred
                                                        size={19}
                                                        className="dark:text-white mr-1"
                                                    />
                                                    Report
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setVisibleShare(!visibleShare);
                                                    }}
                                                    className="flex items-center text-xs text-gray-800 dark:text-gray-200"
                                                >
                                                    <MdShare size={17} className="dark:text-white mr-1"/>{" "}
                                                    Share
                                                </button>
                                                {verified &&
                                                    customerToken &&
                                                    productDetails?.inventories_sum_stock_quantity <
                                                    1 && (
                                                        <button
                                                            onClick={handleRestock}
                                                            className="flex items-center text-xs text-gray-800 dark:text-gray-200"
                                                        >
                                                            <RiStockLine
                                                                size={17}
                                                                className="dark:text-white mr-1"
                                                            />
                                                            Restock Request
                                                        </button>
                                                    )}
                                            </div>
                                            <div className="pt-4">
                                                {productDetails?.product_attributes?.map((attr, i) => {
                                                    const {attribute_values, name} = attr;
                                                    if (name === "default") {
                                                        return null;
                                                    }
                                                    return (
                                                        <div key={i} className="flex items-center">
                                                            <div className="w-16">
                                                                <h4 className="text-base dark:text-white">
                                                                    {name}
                                                                </h4>
                                                            </div>
                                                            <div className="flex items-center gap-1 mb-2">
                                                                {attribute_values?.map((v, i) => {
                                                                    const result = selectedIds?.some(
                                                                        (data) => data.id === v.id
                                                                    );
                                                                    return (
                                                                        <div
                                                                            key={i}
                                                                            className={`cursor-pointer text-xs p-1 dark:text-white px-2 rounded mr-2 ${
                                                                                result
                                                                                    ? "bg-red-400 text-white"
                                                                                    : "border"
                                                                            }`}
                                                                            onClick={() => {
                                                                                let result2 = selectedIds.map(
                                                                                    (data) => {
                                                                                        if (
                                                                                            data.product_attribute_id ===
                                                                                            v.product_attribute_id
                                                                                        ) {
                                                                                            return {
                                                                                                ...data,
                                                                                                id: v.id,
                                                                                                name: v.name,
                                                                                            };
                                                                                        }
                                                                                        return data;
                                                                                    }
                                                                                );

                                                                                setSelectedIds(result2);
                                                                                variantPrice(result2);
                                                                            }}
                                                                        >
                                                                            {v?.name}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <NoContent/>
                            )}
                        </div>
                    )}
                </div>

                <div className="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                    <div className="p-2 grid grid-cols-2 p-lg-0">
                        <div className="grid grid-cols-1 border-r border-b p-2 items-center  dark:border-black">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <CiDeliveryTruck size={22}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Standard Delivery
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    Standard delivery system available
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 border-b p-2 items-center dark:border-black">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <BsCashCoin size={17}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Cash on Delivery
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    Cash on delivery feature is available
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 border-r p-2 items-center border-b dark:border-black">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <TbArrowBigLeftLinesFilled size={17}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Free Return Policy
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    Damaged product can be returned
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 p-2 items-center border-b dark:border-black">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <BiSupport size={17}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Customer Support
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    24/7 customer support available
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 border-r p-2 items-center border-b  dark:border-black">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <MdReportGmailerrorred size={20}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Report to Admin
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    Report/Inform admin for damaged product
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 p-2 items-center border-b dark:border-black">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <MdShare size={17}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Share Option
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    Share with friends via social media
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 border-r dark:border-black p-2 items-center">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <AiOutlineStar size={17}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Review Product
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    Add review after purchasing the product
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 p-2 items-center">
                            <div className="col-span-2">
                                <div
                                    className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-gray-200 dark:bg-gray-900 dark:text-white">
                                    <TfiEmail size={17}/>
                                </div>
                            </div>
                            <div className="col-span-10">
                                <span className="text-sm text-slate-900 font-bold dark:text-gray-300">
                                  Subscription System
                                </span>
                                <p className="text-xs text-slate-500 dark:text-slate-400">
                                    Get latest and offer news on your mail.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProductView;
