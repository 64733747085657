import React, {useState, useEffect, useRef} from "react";
import {BsCheck2All, BsTelephone} from "react-icons/bs";
import {BiLoader, BiNote, BiSelectMultiple} from "react-icons/bi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCartList,
    setUpdate,
} from "../../../Redux/Slices/Cart/cartListSlice";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {fetchAllcustomerInfo} from "../../../Redux/Slices/Customer/customerInfoSlice";
import useStore from "../../../Hooks/useStore";
import AllProductLoader from "../../Loaders/AllProductLoader/AllProductLoader";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import BreadCumb from "../../BreadCumb/BreadCumb";
import {baseUrl, errorNotify, saveInCookie, successNotify} from "../../../Hooks/Helper";
import {MapContainer, TileLayer, Marker, useMapEvents} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {GoArrowLeft, GoArrowRight} from "react-icons/go";
import Button from "../../Button/Button";
import {getPostalCodes} from "../../../Redux/Slices/Location/postalCodeSlice";
import {getAreas} from "../../../Redux/Slices/Location/areaSlice";
import Select from 'react-select'


const CheckoutContainer2 = () => {
    const navigate = useNavigate();
    let globalmsg = "";
    const innerWidth = window?.innerWidth;
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedAddressValue, setSelectedAddressValue] = useState();
    const [charge, setCharge] = useState(0);
    const [shippingPhone, setShippingPhone] = useState();
    const [addresscustomerInfo, setAddresscustomerInfo] = useState([]);
    const dispatch = useDispatch();
    const {cartItems, updates, flash_sale} = useSelector((state) => state?.cartItems);
    const [update, setUpdateChange] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [promoId, setPromoId] = useState("");
    const [discount, setDiscount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const {customerInfo, loading} = useSelector((state) => state.customerInfo);
    const [position, setPosition] = useState([]);
    const mapRef = useRef(null);
    const [showMap, setShowMap] = useState(false);
    const [addressUpdate, setAddressUpdate] = useState(false);
    const [districtName, setDistrictName] = useState("")
    const [subDisName, setSubDisName] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [area, setArea] = useState("")
    const postalCodes = useSelector((state) => state.postalCode.postalCodes)
    const areas = useSelector((state) => state.area.area)

    // new
    const [shippingOption, setShippingOption] = useState([])
    const [selectedShipping, setSelectedShipping] = useState({})






    const HandleMapClick = () => {
        useMapEvents({
            click(e) {
                const {lat, lng} = e?.latlng;
                setPosition([lat, lng]);
            },
        });
        return null;
    };

    useEffect(() => {
        setShippingPhone(customerInfo?.user_data?.phone);
    }, [customerInfo]);
    let totalWeight = 0;
    for (let i = 0; i < cartItems?.length; i++) {
        const element = cartItems[i];
        const weight = element?.product?.weight;
        const quantity = element?.quantity;
        const singleWeight = parseInt(weight) * parseInt(quantity);
        totalWeight = totalWeight + singleWeight;
    }
    //CUSTOMER TOKER AND BASE URL ,USER INFORMATION GET
    const {
        customerToken,
        secondaryToken,
        setPageName,
        countries,
        setDivisionId,
        setCountryId,
        setDistrictId,
        subDisId,
        setSubDisId,
        divisions,
        districts,
        subDistricts,
        districtId,
        divisionId,
        setting,
        selectedBranch,
        setSelectedBranch,
        branchOptions
    } = useStore().data;

    console.log("selectedBranch",selectedBranch)

    // Get PostalCode & Area
    useEffect(() => {
        if (districtName && subDisName) {
            const data = {
                city: districtName,
                thana: subDisName
            }
            dispatch(getPostalCodes(data))
            if (postalCode) {
                const data = {
                    postcode: postalCode
                }
                dispatch(getAreas(data))
            }
        }
    }, [dispatch, districtName, subDisName, postalCode])

    const [addData, setAddData] = useState({});

    // setAddresscustomerInfo(addresses)
    useEffect(() => {
        dispatch(fetchAllcustomerInfo(customerToken));
    }, [dispatch, customerToken]);
    useEffect(() => {
        dispatch(fetchCartList({customerToken, secondaryToken}));
    }, [dispatch, updates, customerToken, secondaryToken]);
    useEffect(() => {
        setPageName("address");
    }, [setPageName]);

    const [orderNote, setOrderNote] = useState("");
    const [submittedValue, setSubmittedValue] = useState("");
    const [showNote, setShowNote] = useState(false);

    const handleTextareaChange = (event) => {
        setOrderNote(event.target.value);
    };

    const handleNoteSubmit = (event) => {
        event.preventDefault();
        setSubmittedValue(orderNote);
    };

    //USER ADDRESS GET
    useEffect(() => {
        const getAddressCustomerInfo = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/user/address-list`, {
                    method: "GET",
                    headers: {
                        Authorization: `bearer ${customerToken}`,
                    },
                });
                if (res.ok && res.status !== 204) {
                    const data = await res.json();
                    setAddresscustomerInfo(data?.data);
                }
            } catch (e) {
                console.error("error", e)
            }
        };

        getAddressCustomerInfo().then();
    }, [customerToken, update, setUpdateChange, addressUpdate]);

    // shipping method list
    useEffect(() => {
        const getShippingMethodLists = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/asset/shipping-method-list`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });


                if (res.ok) {
                    const resData = await res.json();
                    const modifyData = resData?.data?.map(data => {
                        const {id, name} = data
                        return {
                            label: name, value: id
                        }
                    })
                    setShippingOption(modifyData)
                    setSelectedShipping(modifyData[0]);
                } else {
                    const error = await res.json();
                    console.error(error)
                }
            } catch (e) {
                console.error(e)
            }
        };


        getShippingMethodLists().then();
    }, [customerToken, update, setUpdateChange, addressUpdate]);


    // Calculate sub-total
    const subTotal = cartItems?.reduce((accumulator, currentProduct) => {
        const isOnSale = currentProduct?.product_combination?.product?.is_on_sale
        const selling_price = currentProduct?.product_combination?.selling_price
        let price = isOnSale === 1 && flash_sale ? (selling_price - (selling_price * flash_sale?.discount) / 100) : currentProduct?.product_combination?.selling_price;
        return accumulator + price * currentProduct?.product_quantity
    }, 0);
    //ADDITIONAL CHARGE GET
    const [additionalCharge, setAdditionalCharge] = useState();

    useEffect(() => {
        if (!customerToken) {
            return;
        }

        const getAdditionalChargeInfo = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/user/additional-charge`, {
                    method: "GET",
                    headers: {
                        Authorization: `bearer ${customerToken}`,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setAdditionalCharge(resData.data);
                }
            } catch (error) {
                errorNotify(error);
            }
        };

        getAdditionalChargeInfo().then();
    }, [customerToken, update]);
    const [taxTotal, setTaxTotal] = useState(0);

    useEffect(() => {
        let newTaxTotal = 0;
        if (!additionalCharge) {
            return;
        }
        additionalCharge?.forEach((tax) => {
            newTaxTotal +=
                tax.is_percentage === 1 ? (tax.amount * subTotal) / 100 : tax.amount;
        });

        setTaxTotal(newTaxTotal);
    }, [additionalCharge, subTotal]);
    const Total = subTotal + taxTotal + Number(charge) - discount;


    //ORDER PLACE
    const handleSubmit = async () => {
        if (+selectedShipping.value === 1 && !selectedAddress) {
            errorNotify("The shipping address is required");
            return;
        }
        if (totalWeight > 5) {
            errorNotify("Items weight is not more than 5 kg.");
            return;
        }
        if (Number(setting?.is_admin_select_branch) !== 1 && selectedBranch?.id) {
            return errorNotify("Kindly select a branch before placing your order.")
        }

        const formData = new FormData();
        formData.append("payment_method_id", 1);
        formData.append("delivery_method_id", selectedShipping?.value);
        if (promoId) {
            formData.append("promo_id", promoId);
        }
        formData.append("delivery_address_id", +selectedShipping?.value === 1 ? selectedAddress : "");

        if (submittedValue) {
            formData.append("delivery_remarks", submittedValue);
        }

        if (Number(setting?.is_admin_select_branch) === 1) {
            formData.append("shop_branch_id", "")
        } else {
            formData.append("shop_branch_id", selectedBranch?.id)
        }


        try {
            setIsLoading(true);
            const res = await fetch(`${baseUrl.url}/user/order`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${customerToken}`,
                },
                body: formData,
            });
            const resData = await res.json();
            if (res.ok) {
                successNotify("Order place successfully");
                dispatch(setUpdate());
                setTimeout(() => {
                    navigate("/profile/order");
                }, 1000);
            } else {
                resData.errors?.map((error) => errorNotify(error));
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    };

    const handleInputChange2 = (event) => {
        const {name, value} = event.target;
        setAddData((prevData) => ({...prevData, [name]: value}));
    };


    //ADD ADDRESS
    const addAddress = async (e) => {
        e.preventDefault();

        const phoneNumberRegex = /^\+880(1\d{9}|2\d{8}|3\d{8})$/;
        let updatedPhoneNumber = addData?.phone_no?.startsWith("+88") ? addData?.phone_no : `+88${addData?.phone_no}`
        if (!phoneNumberRegex.test(updatedPhoneNumber)) {
            errorNotify("The phone number format is invalid")
            return;
        }

        const postData = {
            upazila_id: +setting?.default_delivery_system !== 3 ? subDisId : "",
            postal_code: +setting?.default_delivery_system !== 3 ? postalCode : " ",
            area: +setting?.default_delivery_system !== 3 ? area : "",
            phone_no: updatedPhoneNumber,
            address: addData?.address,
        }

        if (+setting?.default_delivery_system !== 3 && !postData?.upazila_id) {
            errorNotify("Please select your upazila")
            return
        }
        if (+setting?.default_delivery_system !== 3 && !postData?.postal_code) {
            errorNotify("Postal code is required")
            return
        }
        if (!postData?.address) {
            errorNotify("Address is required")
            return
        }
        // if (position.length === 0) {
        //     errorNotify("Please select your location from the map")
        //     return
        // }
        postData.is_default = 0;
        const formData = new FormData();
        for (const key in postData) {
            const element = postData[key];
            formData.append(`${key}`, element);
        }

        formData.append("lat", +setting?.default_delivery_system === 3 ? position[0] : "")
        formData.append("lng", +setting?.default_delivery_system === 3 ? position[1] : "")

        const res = await fetch(`${baseUrl.url}/user/create-new-address`, {
            method: "POST",
            headers: {
                Authorization: `bearer ${customerToken}`,
            },
            body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
            setAddressUpdate(!addressUpdate);
            setShowAddModal(false);
            successNotify("successfully address created");
            setAddData({
                address: "",
                phone_no: "",

            })

        } else {
            errorNotify(resData?.errors[0])
        }
    };

    useEffect(() => {
        if (!selectedAddress) {
            return;
        }
        const getCharge = async () => {

            try {
                const res = await fetch(
                    `${
                        baseUrl.url
                    }/user/delivery-charge?address_id=${selectedAddress}&total_price=${
                        subTotal + taxTotal - discount
                    }`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `bearer ${customerToken}`,
                        },
                    }
                );
                if (res.ok) {
                    const resData = await res.json();
                    setCharge(resData?.data);
                } else {
                    const resData = await res.json();
                    errorNotify(resData?.errors[0])
                    setCharge(0)
                    setSelectedAddress(null)
                    setSelectedAddressValue({})

                }
            } catch (e) {
                console.error("error",e)
            }

        };

        getCharge().then();
    }, [selectedAddress, update, customerToken, discount, taxTotal, subTotal]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const data = {
        heading: "Final Checkout",
        paragraph: null,
    };


    const navigationData = [
        {
            page: "Home",
            route: "/",
        },
        {
            page: "Cart",
            route: "/cart",
        },
        {
            page: "Checkout",
            route: "/checkout",
        },
    ];

    const handleAddPromoCode = async (e) => {
        e.preventDefault();
        if (!promoCode) {
            errorNotify("Promo code is required");
            return;
        }
        const postData = {
            promo_code: promoCode,
        };

        try {
            const res = await fetch(`${baseUrl.url}/user/add-promo`, {
                method: "POST",
                headers: {
                    "Content-type": "Application/json",
                    Authorization: `bearer ${customerToken}`,
                },
                body: JSON.stringify(postData),
            });
            const resData = await res.json();
            if (res.ok) {
                setUpdate(!update);
                setPromoId(resData?.data?.promo_id);
                setDiscount(resData?.data?.promo_discount);
            } else {
                errorNotify(resData?.errors[0]);
            }
        } catch (error) {
            console.error(error);
        }
    };



    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {loading ? (
                <AllProductLoader/>
            ) : (
                <div>
                    <Rodal
                        visible={showAddModal}
                        enterAnimation="flip"
                        animation="slideRight"
                        onClose={() => {
                            setShowAddModal(!showAddModal)
                            setAddData({
                                address: "",
                                phone_no: ""
                            })
                        }}
                        customStyles={{
                            height: "auto",
                            width: innerWidth > 1024 ? "40%" : "90%",
                            bottom: "auto",
                            top: "10%",
                            transform: "translateY(-50%)",
                        }}
                    >
                        <p className="text-xl border-b py-3 text-black">Create New Address</p>
                        <form onSubmit={addAddress} className=" py-3">
                            <div className={`grid grid-cols-12 gap-3 ${showMap && "hidden"}`}>

                                {
                                    +setting.default_delivery_system !== 3 &&
                                    <div className="col-span-12 lg:col-span-6">
                                        <label className="font-semibold text-gray-800" htmlFor="">
                                            Country
                                        </label>
                                        <select
                                            className="w-full border-b bg-transparent border-gray-500 focus:outline-none"
                                            onChange={(e) => setCountryId(e.target.value)}
                                            name="country_id"
                                            id=""
                                        >
                                            <option className="" value="">
                                                --Please select country--
                                            </option>
                                            {countries?.map((country, i) => (
                                                <option key={i} value={country?.id}>
                                                    {country?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                }

                                {

                                    +setting.default_delivery_system !== 3 &&

                                    <div className="col-span-12 lg:col-span-6 w-full">
                                        <label className="font-semibold text-gray-800" htmlFor="">
                                            Division
                                        </label>
                                        <select
                                            name="division_id"
                                            onChange={(e) => {
                                                setDivisionId(e.target.value);
                                                setDistrictId("");
                                                setSubDisId("");
                                                setSubDisName("")
                                                setDistrictName("")
                                                setPostalCode("")
                                                setArea("")
                                            }}
                                            value={divisionId}
                                            // onChange={handleInputChange2}
                                            className="text-black form-control border-0 border-b border-gray-500 rounded-0  bg-transparent w-full focus:outline-none"
                                            id=""
                                        >

                                            {divisions?.length > 0 ?
                                                <>
                                                    <option>Select Division</option>
                                                    {
                                                        divisions?.map((division, i) => (
                                                            <option key={i} value={division?.id}>
                                                                {division?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <option value="">--Select country first--</option>
                                            }
                                        </select>
                                    </div>


                                }

                                {
                                    +setting.default_delivery_system !== 3 &&
                                    <div className="col-span-12 lg:col-span-6">
                                        <label className="font-semibold text-gray-800" htmlFor="">
                                            District
                                        </label>
                                        <select
                                            name="district_id"
                                            onChange={(e) => {
                                                setDistrictName(e.target.selectedOptions[0].text)
                                                setDistrictId(e.target.value);
                                                setSubDisId("");
                                                setSubDisName("")
                                                setPostalCode("")
                                                setArea("")
                                            }}
                                            value={districtId}
                                            // onChange={handleInputChange2}
                                            className="w-full border-gray-500 text-black form-control border-0 border-b rounded-0  bg-transparent  focus:outline-none"
                                            id=""
                                        >
                                            {districts?.length > 0 ?
                                                <>
                                                    <option>Select Districts</option>
                                                    {
                                                        districts?.map((district, i) => (
                                                            <option key={i} value={district?.id}>
                                                                {district?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <option value="">--Select division first--</option>
                                            }
                                        </select>
                                    </div>
                                }

                                {
                                    +setting.default_delivery_system !== 3 &&

                                    <div className="col-span-12 lg:col-span-6">
                                        <label className="font-semibold text-gray-800" htmlFor="">
                                            Upazila
                                        </label>
                                        <select
                                            name="upazila_id"
                                            onChange={(e) => {
                                                setSubDisId(e.target.value)
                                                setSubDisName(e.target.selectedOptions?.length > 0 && e.target.selectedOptions[0].text)
                                                setPostalCode("")
                                                setArea("")
                                            }}
                                            value={subDisId}
                                            className="w-full border-gray-500 border-0 border-b rounded-0  bg-transparent text-black  focus:outline-none "
                                            id=""
                                        >
                                            {subDistricts?.length > 0 ?
                                                <>
                                                    <option>Select Upazila</option>
                                                    {
                                                        subDistricts?.map((subD, i) => (
                                                            <option key={i} value={subD?.id}>
                                                                {subD?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <option value="">--Select district first--</option>
                                            }
                                        </select>
                                    </div>

                                }

                                {
                                    +setting.default_delivery_system !== 3 &&
                                    <div className="col-span-12 lg:col-span-6">
                                        <label className="font-semibold text-gray-800" htmlFor="">
                                            Postal/Zip
                                        </label>
                                        <select
                                            name="postal_code"
                                            onChange={(e) => {
                                                setPostalCode(e.target.value)
                                                setArea("")
                                            }}
                                            // onChange={handleInputChange2}
                                            value={postalCode}
                                            className="w-full border-gray-500 border-0 border-b rounded-0  bg-transparent text-black  focus:outline-none "
                                            id=""
                                        >
                                            {postalCodes?.length > 0 ?
                                                <>
                                                    <option>Select PostCode</option>
                                                    {
                                                        postalCodes?.map((pCode, i) => (
                                                            <option key={i} value={pCode?.id}>
                                                                {pCode?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <option value="">--Select Upazila first--</option>
                                            }
                                        </select>
                                    </div>

                                }

                                {
                                    +setting.default_delivery_system !== 3 &&
                                    <div className="col-span-12 lg:col-span-6">
                                        <label className="font-semibold text-gray-800" htmlFor="">
                                            Area
                                        </label>
                                        <select
                                            name="area"
                                            onClick={(e) => {
                                                setArea(e.target.value)
                                            }}
                                            onChange={handleInputChange2}
                                            className="w-full border-gray-500 border-0 border-b rounded-0  bg-transparent text-black  focus:outline-none "
                                            id=""
                                        >
                                            {areas?.length > 0 ?
                                                <>
                                                    <option>Select PostCode</option>
                                                    {
                                                        areas?.map((pArea, i) => (
                                                            <option key={i} value={pArea?.id}>
                                                                {pArea?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <option value="">--Select PostCode first--</option>
                                            }
                                        </select>
                                    </div>
                                }

                                <div className="col-span-12 lg:col-span-6">
                                    <label className="font-semibold text-gray-800 block" htmlFor="">
                                        Phone number
                                    </label>
                                    <div className="flex">
                                        <span>+88</span>
                                        <input
                                            name="phone_no"
                                            value={addData?.phone_no}
                                            onChange={handleInputChange2}
                                            type="tel"
                                            className="w-full border-0 border-b border-gray-500 rounded-0  bg-transparent text-black focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-12">
                                    <label className="font-semibold text-gray-800" htmlFor="">
                                        Address
                                    </label>
                                    <textarea
                                        name="address"
                                        value={addData?.address}
                                        onChange={handleInputChange2}
                                        className="w-full text-black border-gray-500 form-control border p-5 rounded  bg-transparent focus:outline-none"
                                    />
                                </div>

                                {
                                    +setting.default_delivery_system === 3 &&
                                    <div className="flex justify-end col-span-12">
                                    <span className="flex items-center gap-1 text-red-400 cursor-pointer"
                                          onClick={() => setShowMap(true)}>Next <GoArrowRight size="21"/></span>
                                    </div>
                                }
                            </div>

                            {
                                +setting.default_delivery_system !== 3 &&
                                <div className="mt-4">
                                    <Button submit={true} size={"md"} title={"Add address"}/>
                                </div>
                            }

                            {
                                +setting.default_delivery_system === 3 && showMap && <div className="col-span-12 block">
                                    <div className="pb-1">
                                        <span className="flex items-center gap-1 text-red-400 cursor-pointer "
                                              onClick={() => setShowMap(false)}><GoArrowLeft size="21"/>Previous</span>
                                    </div>
                                    <MapContainer
                                        center={[23.76, 90.38]}
                                        zoom={13}
                                        style={{height: "400px", width: "100%"}}
                                        ref={mapRef}
                                    >
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        {position?.length > 0 && (
                                            <Marker
                                                position={position}
                                                icon={L.icon({
                                                    iconUrl: `https://www.iconpacks.net/icons/2/free-location-icon-2955-thumb.png`,
                                                    iconSize: [32, 32],
                                                    iconAnchor: [16, 32],
                                                })}
                                            >
                                                {/* <Popup>Selected Location</Popup> */}
                                            </Marker>
                                        )}
                                        <HandleMapClick/>
                                    </MapContainer>
                                    <div className="mt-4">
                                        <Button submit={true} size={"md"} title={"Add address"}/>
                                    </div>
                                </div>
                            }
                        </form>
                    </Rodal>

                    <div className="container">
                        <div className="bg-white dark:bg-gray-800 p-4 my-3">
                            <SectionHeading2 data={data}/>
                            <BreadCumb data={navigationData}/>
                            <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-2 mt-6">
                                <div className="col-span-12 lg:col-span-6">
                                    <div className="mt-2 flex gap-2 items-center rounded-lg px-3 shadow py-6">
                                        <div className="">
                                            <div
                                                className="w-10 h-10 bg-red-400 text-white rounded-full flex items-center justify-center">
                                                <span className="dark:text-white">01</span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div>
                                                <h4 className="text-base flex items-center m-0 gap-4 dark:text-white whitespace-nowrap">
                                                    Customer Information
                                                    <BsCheck2All
                                                        size={22}
                                                        className="font-bold dark:text-white"
                                                    />
                                                </h4>

                                                <div className="flex gap-0 lg:gap-1">
                                                    <p className="text-sm font-bold dark:text-white whitespace-nowrap">
                                                        {customerInfo?.user_data?.name}
                                                    </p>
                                                    <p className="text-sm font-bold dark:text-white">
                                                        ||
                                                    </p>
                                                    <p className="text-sm font-bold dark:text-white">
                                                        {customerInfo?.user_data?.username}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="bg-white shadow mt-2   rounded-lg px-3  py-6 dark:bg-slate-800">
                                        <div>
                                            <div className="grid grid-cols-12 gap-2">
                                                <div className="col-span-12 lg:col-span-1">
                                                    <div
                                                        className="w-10 h-10 bg-red-400 text-white rounded-full flex items-center justify-center">
                                                        <span className="dark:text-white">02</span>
                                                    </div>
                                                </div>
                                                <div className="col-span-12 lg:col-span-11">
                                                    <div>
                                                        <h4 className="text-base flex gap-4 m-0 items-center dark:text-white ">
                                                            Delivery Methods{" "}
                                                            {selectedAddress && shippingPhone?.length === 11 && (
                                                                <BsCheck2All
                                                                    size={22}
                                                                    className="font-bold dark:text-white"
                                                                />
                                                            )}
                                                        </h4>
                                                        {/*{selectedAddress && shippingPhone?.length === 11 && (*/}
                                                        {/*    <div className="flex gap-2 ">*/}
                                                        {/*        <input type="checkbox" checked={true}/>*/}
                                                        {/*        <p className="text-sm dark:text-white">*/}
                                                        {/*            Home Delivery*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}
                                                    </div>

                                                    {
                                                        shippingOption?.length > 0 &&
                                                        <Select
                                                            className="w-full mt-2"
                                                            options={shippingOption}
                                                            defaultValue={selectedShipping}
                                                            placeholder={"Select shipping method"}
                                                            onChange={(e) => {
                                                                setSelectedShipping(e)
                                                                if (e.value !== 1) {
                                                                    setCharge(0)
                                                                }
                                                                setSelectedAddressValue({})
                                                                setSelectedAddress(null)

                                                            }}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        +selectedShipping?.value === 1 &&

                                        <>

                                            <div className="mt-2">
                                                <div
                                                    className="flex justify-between items-center rounded-lg px-3 py-6 bg-white shadow dark:bg-slate-800">
                                                    <div className="flex gap-2">
                                                        <div
                                                            className="w-10 h-10 bg-red-400 text-white rounded-full flex items-center justify-center">
                                                            <span className="dark:text-white">03</span>
                                                        </div>
                                                        <div>
                                                            <h4 className="text-base flex items-start m-0 gap-4 dark:text-white">
                                                                Shipping Information{" "}
                                                                {selectedAddress &&
                                                                    shippingPhone?.length === 11 && (
                                                                        <BsCheck2All
                                                                            size={22}
                                                                            className="font-bold dark:text-white"
                                                                        />
                                                                    )}
                                                            </h4>
                                                            {selectedAddress && shippingPhone?.length === 11 && (
                                                                <>

                                                                    {
                                                                        +setting.default_delivery_system !== 3 &&
                                                                        <p className="text-sm font-bold dark:text-white">
                                                                            {selectedAddressValue?.country},
                                                                            {selectedAddressValue?.divison},
                                                                            {selectedAddressValue?.district},
                                                                            {selectedAddressValue?.sub_district}
                                                                        </p>
                                                                    }


                                                                    <p className="dark:text-white">
                                                                        {selectedAddressValue?.address}
                                                                    </p>
                                                                    {shippingPhone && (
                                                                        <p className="dark:text-white">
                                                                            {shippingPhone}
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <Button size={"sm"} func={() => setShowAddModal(!showAddModal)}
                                                                title={"Add Address"}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="rounded-lg p-2 ml-0 lg:ml-6 bg-white my-2 items-center dark:bg-slate-800">
                                                <div className="lg:space-y-4 lg:px-4 lg:py-4">
                                                    <div>
                                                        <form className="w-full max-w-sm">
                                                            <label className="text-sm dark:text-white">
                                                                Shipping Phone Number
                                                            </label>
                                                            <div
                                                                className="flex items-center border-b border-teal-500 py-2 gap-1">
                                                                <span className="dark:text-white">
                                                                  {" "}
                                                                    <BsTelephone/>
                                                                </span>
                                                                <span className="dark:text-white">
                                                            {customerInfo?.user_data?.phone}
                                                        </span>
                                                            </div>

                                                            {
                                                                <p id="errorMsg" className="text-xs text-red-600">
                                                                    {globalmsg}
                                                                </p>
                                                            }
                                                        </form>
                                                    </div>
                                                    <div className="text-sm flex items-center gap-x-1 my-2">
                                                        <BiSelectMultiple
                                                            className="text-yellow-400"
                                                            size={15}
                                                        />
                                                        <p className="dark:text-white">
                                                            Select your preferred shipping address.
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-1">
                                                        {addresscustomerInfo &&
                                                            addresscustomerInfo?.map((userAddress, index) => (
                                                                <div key={index}>
                                                                    <div
                                                                        className={`${
                                                                            selectedAddress === userAddress?.id
                                                                                ? " border"
                                                                                : ""
                                                                        } cursor-pointer grid grid-cols-12 rounded-sm px-4 py-4 text-sm border lg:my-1`}
                                                                        onClick={() => {
                                                                            setSelectedAddress(userAddress?.id);
                                                                            setSelectedAddressValue({
                                                                                country:
                                                                                userAddress?.upazila?.district?.division
                                                                                    ?.country?.name,
                                                                                divison:
                                                                                userAddress?.upazila?.district?.division
                                                                                    ?.name,
                                                                                district:
                                                                                userAddress?.upazila?.district?.name,
                                                                                sub_district: userAddress?.upazila?.name,
                                                                                address: userAddress?.address,
                                                                            });
                                                                            setUpdateChange(!update);
                                                                        }}
                                                                    >
                                                                        <div className="col-span-11">

                                                                            {
                                                                                +setting.default_delivery_system !== 3 &&
                                                                                <p className="font-bold text-sm flex flex-wrap dark:text-white whitespace-wrap lg:whitespace-nowrap">
                                                                                    {userAddress?.upazila?.district?.division?.country?.name},
                                                                                    {userAddress?.upazila?.district?.division?.name},
                                                                                    {userAddress?.upazila?.district?.name},
                                                                                    {userAddress?.upazila?.name}
                                                                                </p>
                                                                            }

                                                                            <p className="whitespace-nowrap dark:text-white">
                                                                                {userAddress?.address}
                                                                            </p>
                                                                            {selectedAddress === userAddress?.id && (
                                                                                <div
                                                                                    className="text-xs font-bold flex items-center gap-x-1">
                                      <span>
                                        {" "}
                                          <BiNote
                                              className="text-red-500"
                                              size={15}
                                          />
                                      </span>
                                                                                    <p className="dark:text-white">
                                        <span className="text-green-500">
                                          {charge.toFixed(2)}
                                        </span>{" "}
                                                                                        tk delivery charge will be
                                                                                        included in
                                                                                        your cart
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-span-1">
                                                                            {selectedAddress === userAddress?.id && (
                                                                                <span>
                                                                                  <BsCheck2All
                                                                                      size={22}
                                                                                      className="dark:text-white"
                                                                                  />
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    {/* here select branch when not selected any branch */}

                                                    {
                                                        !selectedBranch?.id && Number(setting.is_admin_select_branch) !== 1 &&
                                                        <div>
                                                            <Select
                                                                className="w-full mt-2"
                                                                options={branchOptions}
                                                                defaultValue={selectedBranch}
                                                                placeholder={"Select shipping method"}
                                                                onChange={(e) => {
                                                                    saveInCookie("branch", JSON.stringify(e))
                                                                    setSelectedBranch(e)
                                                                }}/>
                                                        </div>

                                                    }

                                                </div>


                                            </div>


                                            <div
                                                className="bg-white shadow mt-2 flex justify-between  items-start rounded-lg px-3  py-6 dark:bg-slate-800">
                                                <div className="flex gap-2">
                                                    <div
                                                        className="w-10 h-10 bg-red-400 text-white rounded-full flex items-center justify-center">
                                                        <span className="dark:text-white">04</span>
                                                    </div>
                                                    <h4 className="text-base flex gap-4 m-0 items-center dark:text-white whitespace-nowrap p-2 ">
                                                        Delivery Notes
                                                    </h4>
                                                </div>
                                                <div className="col-span-12 lg:col-span-11">
                                                    <div>
                                                        <div className="flex justify-between items-center">
                                                        <Button size={"sm"} title={"Add Notes"}
                                                                    func={() => setShowNote(!showNote)}/>
                                                        </div>

                                                        <div>
                                                            {orderNote && !showNote && (
                                                                <p className="dark:text-white">{orderNote}</p>
                                                            )}
                                                        </div>
                                                        {showNote && (
                                                            <form onSubmit={handleNoteSubmit}>
                                                                    <textarea
                                                                        className="border p-4 w-full mt-3 dark:bg-slate-800 dark:text-white"
                                                                        value={orderNote}
                                                                        onChange={handleTextareaChange}
                                                                        rows={2}
                                                                        cols={50}
                                                                        placeholder="Enter your text here "
                                                                    />
                                                                <br/>
                                                                <Button size={"sm"} title={"Submit"}
                                                                        func={() => setShowNote(!showNote)}/>
                                                            </form>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>


                                            <div
                                                className="bg-white shadow mt-2 flex gap-2 items-center rounded-lg px-3  py-6 dark:bg-slate-800">
                                                <div className="">
                                                    <div
                                                        className="w-10 h-10 bg-red-400 text-white rounded-full flex items-center justify-center">
                                                        <span
                                                            className="dark:text-white">{+selectedShipping?.value === 1 ? "05" : "03"}</span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div>
                                                        <h4 className="text-base gap-4 flex m-0 items-center dark:text-white">
                                                            Payment Methods{" "}
                                                            {selectedAddress && shippingPhone?.length === 11 && (
                                                                <BsCheck2All
                                                                    size={22}
                                                                    className="font-bold dark:text-white"
                                                                />
                                                            )}
                                                        </h4>
                                                        {selectedAddress && shippingPhone?.length === 11 && (
                                                            <div className="flex gap-2 ">
                                                                <input type="checkbox" checked={true}/>
                                                                <p className="text-sm dark:text-white">
                                                                    Cash On Delivery
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }


                                </div>
                                <div className="col-span-12 lg:col-span-2"></div>
                                <div className="col-span-4">
                                    <div className="rounded-lg overflow-hidden">
                                        <div className="my-4 p-2 rounded-lg">
                                            <h1 className="text-slate-700 dark:text-white text-xl">
                                                Order Summary
                                            </h1>
                                            <p className="text-sm dark:text-white">
                                                Calculations of your ordered product with shipping cost
                                                and vat
                                            </p>
                                            {cartItems?.length > 0 ? (
                                                <div className="mt-8">
                                                    {cartItems?.map((product, index) => {
                                                        const isOnSale = product?.product_combination?.product?.is_on_sale
                                                        const selling_price = product?.product_combination?.selling_price
                                                        let price = isOnSale === 1 && flash_sale ? (selling_price - (selling_price * flash_sale?.discount) / 100) : product?.product_combination?.selling_price;

                                                        return (
                                                            <div
                                                                className="p-2 lg:p-1 grid grid-cols-12 gap-2 border-b relative  mt-1"
                                                                key={index}
                                                            >
                                                                <div className="col-span-2 overflow-hidden">
                                                                    <img
                                                                        className="h-full w-full overflow-hidden object-cover bg-slate-100"
                                                                        src={`${baseUrl.img}${product?.product_combination?.product?.thumbnail_image}`}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="col-span-10">
                                                                    <div className="flex items-start justify-between">
                                                                        <div>
                                                                            <p className="font-bold text-sm dark:text-white">
                                                                                {
                                                                                    product?.product_combination?.product
                                                                                        ?.name
                                                                                }
                                                                            </p>
                                                                            <div>
                                                                                {product?.product_combination?.attribute_values?.map(
                                                                                    (value, j) =>
                                                                                        value?.name !== "default" && (
                                                                                            <p
                                                                                                key={j}
                                                                                                className="text-xs dark:text-white"
                                                                                            >
                                                                                                {value?.attribute?.name}:{" "}
                                                                                                {value?.name}
                                                                                            </p>
                                                                                        )
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <span
                                                                            className="dark:text-white whitespace-nowrap">
                                      ৳{" "}
                                                                            {
                                                                                price.toFixed(2)
                                                                            }
                                                                            X {product?.product_quantity}
                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}

                                                    <div className="p-2 lg:p-1">
                                                        <div className="flex justify-between items-center">
                                                            <p className="text-sm font-normal dark:text-white">
                                                                Subtotal
                                                            </p>
                                                            <p className="text-sm font-bold dark:text-white">
                                                                ৳ {subTotal.toFixed(2)}
                                                            </p>
                                                        </div>
                                                        <form className="grid grid-cols-12 gap-1 py-4">
                                                            <div className="col-span-9 xl:col-span-10">
                                                                <input
                                                                    type="text"
                                                                    onChange={(e) => setPromoCode(e.target.value)}
                                                                    placeholder="Apply Coupon"
                                                                    className="w-full focus:outline-none border p-2 dark:bg-slate-800 dark:text-white"
                                                                />
                                                            </div>
                                                            <div className="col-span-3 xl:col-span-2">
                                                                {/* <button
                                  type="submit"
                                  onClick={handleAddPromoCode}
                                  className="w-full bg-blue-500 border text-white shadow cursor-pointer p-2"
                                >
                                  Apply
                                </button> */}
                                                                <Button size={"md"} title={"Apply"}
                                                                        func={handleAddPromoCode}/>
                                                            </div>
                                                        </form>
                                                        <div className="flex justify-between items-center">
                                                            <p className="text-sm font-normal dark:text-white">
                                                                Shipping
                                                            </p>


                                                            <p className="text-sm font-bold dark:text-white">
                                                                ৳ {charge ? charge.toFixed(2) : 0}
                                                            </p>

                                                        </div>
                                                        <div className="flex justify-between items-center">
                                                            <p className="text-sm font-normal dark:text-white">
                                                                Additional Charge
                                                            </p>
                                                            <p className="text-sm font-bold dark:text-white">
                                                                ৳ {taxTotal ? taxTotal.toFixed(2) : 0}
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between items-center">
                                                            <p className="text-sm font-normal dark:text-white">
                                                                Discount
                                                            </p>
                                                            <p className="text-sm font-bold dark:text-white">
                                                                ৳ {discount ? discount.toFixed(2) : 0}
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between items-center">
                                                            <p className="text-sm font-bold dark:text-white">
                                                                Total
                                                            </p>
                                                            <div className="text-sm">
                                                                {Total && Total ? (
                                                                    <p className="text-sm font-bold dark:text-white">
                                                                        ৳ {Total.toFixed(2)}
                                                                    </p>
                                                                ) : (
                                                                    <p className="text-sm font-bold dark:text-white">
                                                                        ৳ 0
                                                                    </p>
                                                                )}{" "}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Button size={"md"} func={handleSubmit} title={"Place Order"}
                                                            classes={"w-full mt-3"} disable={isLoading && true}
                                                            icon={isLoading && (
                                                                <BiLoader size={20} className="text-white"/>
                                                            )}/>
                                                </div>
                                            ) : (
                                                <div className="flex items-center justify-center">
                                                    <div>
                                                        <h4 className="text-center text-base dark:text-white">
                                                            Cart is empty
                                                        </h4>
                                                        <p className="text-xs text-center dark:text-white ">
                                                            No items added in cart
                                                        </p>
                                                        <img
                                                            src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-7359557-6024626.png"
                                                            alt=""
                                                            className="w-20 h-20  ms-3 mt-2"
                                                        />
                                                        <Link to="/">
                                                            {" "}
                                                            <button
                                                                className="mt-3 w-3/4 ml-4 rounded-sm  bg-gray-900 px-2 py-1 opacity-80 font-sm text-white dark:text-white">
                                                                Go to Buy
                                                            </button>
                                                            {" "}
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CheckoutContainer2;