import React, {useEffect, useState} from 'react';
import './Main.css'
import {Outlet} from 'react-router-dom';
import Navbar from "../Components/Navs/Navbar/Navbar";
import NavBar2 from '../Components/Navs/NavBar2/NavBar2';
import NavBar3 from '../Components/Navs/NavBar3/NavBar3';
import NavBar4 from '../Components/Navs/NavBar4/NavBar4';
import Footer from "../Components/Footers/Footer/Footer"
import Footer2 from '../Components/Footers/Footer2/Footer2';
import Footer3 from '../Components/Footers/Footer3/Footer3';
import NavBar5 from '../Components/Navs/NavBar5/NavBar5';
import {fetchThemeData} from '../Redux/Slices/theme/themeSlice';
import {useDispatch, useSelector} from 'react-redux';
import {baseUrl} from '../Hooks/Helper';
import Navbar6 from '../Components/Navs/Navbar6/Navbar6';
import Footer4 from '../Components/Footers/Footer4/Footer4';
import Footer5 from '../Components/Footers/Footer5/Footer5';
import Footer6 from '../Components/Footers/Footer6/Footer6';
import {fetchHomeData} from '../Redux/Slices/rootSlice/rootSlice';

const Main = () => {
    const dispatch = useDispatch()
    const {themeData} = useSelector((state) => state.theme)
    const {data} = useSelector((state) => state.home);

    useEffect(() => {
        dispatch(fetchThemeData())
        dispatch(fetchHomeData());
    }, [dispatch])

    const nav = themeData?.find((item => item?.id === 1))
    const foot = themeData?.find((item => item?.id === 12))

    const [staticMenus, setStaticMenus] = useState([]);
    useEffect(() => {

        const getStaticMenu = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/static-menu`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });
                const resData = await res.json();
                setStaticMenus(resData.data);
            } catch (error) {
            }
        };
        getStaticMenu().then();
    }, []);
    return (
        <>
            {
                nav?.id === 1 && <>
                    {
                        nav?.is_active === 1 && <>
                            {
                                nav?.value === 1 ? <Navbar staticMenus={staticMenus}
                                                           flash={data?.flash_sale ? data?.flash_sale : null}/> : nav?.value === 2 ?
                                    <NavBar2 staticMenus={staticMenus}
                                             flash={data?.flash_sale ? data?.flash_sale : null}/> : nav?.value === 3 ?
                                        <NavBar3 staticMenus={staticMenus}
                                                 flash={data?.flash_sale ? data?.flash_sale : null}/> : nav?.value === 4 ?
                                            <NavBar4 staticMenus={staticMenus}
                                                     flash={data?.flash_sale ? data?.flash_sale : null}/> : nav?.value === 5 ? (
                                                    <NavBar5 staticMenus={staticMenus}
                                                             flash={data?.flash_sale ? data?.flash_sale : null}/>) :
                                                <Navbar6 staticMenus={staticMenus}
                                                         flash={data?.flash_sale ? data?.flash_sale : null}/>
                            }
                        </>
                    }
                </>
            }
            <Outlet/>
            {
                foot?.id === 12 && <>
                    {
                        foot?.is_active === 1 && <>
                            {
                                foot?.value === 1 ? <Footer staticMenus={staticMenus}/> : foot?.value === 2 ?
                                    <Footer2 staticMenus={staticMenus}/> : foot?.value === 3 ?
                                        <Footer3 staticMenus={staticMenus}/> : foot?.value === 4 ? (
                                            <Footer4/>) : foot?.value === 5 ? (<Footer5/>) : (
                                            <Footer6 staticMenus={staticMenus}/>)
                            }
                        </>
                    }
                </>
            }
        </>
    );
};


export default Main;