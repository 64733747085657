import React, {useEffect, useState} from "react";
import "./CartContainer.css";
import {Link, useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import {fetchCartList, setUpdate} from "../../../Redux/Slices/Cart/cartListSlice";
import {BsArrowRight} from "react-icons/bs";
import {baseUrl, errorNotify, successNotify} from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import BreadCumb from "../../BreadCumb/BreadCumb";
import Loader2 from "../../Loaders/Loader2/Loader2";
import Button from "../../Button/Button";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import Rodal from "rodal";
import {GoPlus} from "react-icons/go";
import {LuMinus, LuPlus} from "react-icons/lu";
import {RxCross1} from "react-icons/rx";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import {FiMinus} from "react-icons/fi";

const CartContainer = () => {
    const {customerToken, secondaryToken, update} = useStore().data;
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [addonsList, setAddonsList] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const {cartItems, flash_sale, loading, updates} = useSelector((state) => state?.cartItems);

    useEffect(() => {
        dispatch(fetchCartList({customerToken, secondaryToken}));
    }, [dispatch, updates, customerToken, secondaryToken, update]);

    const navigate = useNavigate();
    let subTotal = 0;
    let shippingCharge = 0;
    let tax = 0;
    const updateQuantity = async (
        id,
        product_combination_id,
        quantity,
        type,
        product_inventory_qty
    ) => {
        const formData = {};
        formData.product_combination_id = product_combination_id;
        formData.user_unique_id = secondaryToken;
        if (type === "increment") {
            formData.quantity = +quantity + 1;
        } else {
            formData.quantity = +quantity - 1;
        }
        if (formData.quantity < 1) {
            errorNotify("You can't order less than one unit of our products");
            return;
        }
        if (formData.quantity > product_inventory_qty) {
            errorNotify("You can't order more than the available stock quantity");
            return;
        }
        try {
            const res = await fetch(`${baseUrl.url}${customerToken?.length > 20 ? '/user' : ''}/cart-update/${id}`, {
                method: "PUT",
                credentials: "include",
                headers: {
                    "content-type": "application/json",
                    Authorization: `${customerToken.length > 20 ? `bearer ${customerToken}` : baseUrl.token}`,
                },
                body: JSON.stringify(formData),
            });
            const resData = await res.json();
            if (res.ok && res.status !== 204) {
                dispatch(setUpdate());
                successNotify("Product quantity is updated successfully");
            } else {
                errorNotify(resData.message);
            }
        } catch (e) {
            console.error(e)
        }

        // if (customerToken && customerToken?.length > 20) {
        //   const res = await fetch(`${baseUrl.url}/user/cart-update/${id}`, {
        //     method: "PUT",
        //     credentials: "include",
        //     headers: {
        //       "content-type": "application/json",
        //       Authorization: `bearer ${customerToken}`,
        //     },
        //     body: JSON.stringify(formData),
        //   });
        //   const resData = await res.json();
        //   if (resData.status === true) {
        //     dispatch(setUpdate());
        //     successNotify("Product quantity is updated successfully");
        //   } else {
        //     errorNotify(resData.message);
        //   }
        // } else {
        //   const res = await fetch(`${baseUrl.url}/cart-update/${id}`, {
        //     method: "PUT",
        //     headers: {
        //       "content-type": "application/json",
        //       Authorization: baseUrl.token,
        //     },
        //     body: JSON.stringify(formData),
        //   });
        //   const resData = await res.json();
        //   if (resData.status === true) {
        //     dispatch(setUpdate());
        //     successNotify("Product quantity is updated successfully");
        //   } else {
        //     errorNotify(
        //       resData?.errors?.map((err) => {
        //         return err;
        //       })
        //     );
        //   }
        // }
    };


    // const deleteCart = async (id) => {
    //     if (customerToken && customerToken?.length > 20) {
    //         const res = await fetch(`${baseUrl.url}/user/cart-delete/${id}`, {
    //             method: "DELETE",
    //             credentials: "include",
    //             headers: {
    //                 Authorization: `bearer ${customerToken}`,
    //             },
    //         });
    //         if (res.ok) {
    //             dispatch(setUpdate());
    //             successNotify("Your cart item is deleted successfully");
    //         }
    //     } else {
    //         const res = await fetch(`${baseUrl.url}/cart-delete/${id}`, {
    //             method: "DELETE",
    //             credentials: "include",
    //             headers: {
    //                 "content-type": "application/json",
    //                 Authorization: baseUrl.token,
    //             },
    //         });
    //         if (res.ok) {
    //             dispatch(setUpdate());
    //             successNotify("Your cart item is deleted successfully");
    //         }
    //     }
    // };


    const deleteCart = async (id) => {
        try {
            // Check if customer token is valid and proceed accordingly
            if (customerToken && customerToken?.length > 20) {
                const res = await fetch(`${baseUrl.url}/user/cart-delete/${id}`, {
                    method: "DELETE",
                    credentials: "include",
                    headers: {
                        Authorization: `bearer ${customerToken}`,
                    },
                });

                if (res.ok) {
                    dispatch(setUpdate());
                    successNotify("Your cart item is deleted successfully");
                } else {
                    // Handle response errors
                    const errorData = await res.json();
                    // throw new Error(errorData.message || "Failed to delete cart item");
                    errorNotify(errorData.message || "Failed to delete cart item");
                }
            } else {
                const res = await fetch(`${baseUrl.url}/cart-delete/${id}`, {
                    method: "DELETE",
                    credentials: "include",
                    headers: {
                        "content-type": "application/json",
                        Authorization: baseUrl.token,
                    },
                });

                if (res.ok) {
                    dispatch(setUpdate());
                    successNotify("Your cart item is deleted successfully");
                } else {
                    // Handle response errors
                    const errorData = await res.json();
                    errorNotify(errorData.message || "Failed to delete cart item");
                }
            }
        } catch (error) {
            // Handle any errors that occur during the fetch or processing
            errorNotify(`Error deleting cart item: ${error.message}`);
        }
    };


    const goCheckout = () => {
        navigate("/checkout");
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const data = {
        heading: "My Cart",
        paragraph: null,
    };
    const navigationData = [
        {
            page: "Home",
            route: "/",
        },
        {
            page: "Cart",
            route: "/cart",
        },
        {
            page: "Cart items",
            route: "#",
        },
    ];

    // addons update or create
    const handleAddOnsData = async () => {
        const selectedProduct = addonsList.filter((data) => data.selected === true)
        const modifyData = selectedProduct.map((data) => {
            let obj = {}
            obj.add_on_id = data.id
            obj.quantity = data.quantity
            return obj
        })

        const postData = {
            add_ons: modifyData,
        }
        try {
            const res = await fetch(`${baseUrl.url}${customerToken?.length > 20 ? '/user' : ''}/cart-addon-update/${selectedId}`, {
                method: "PUT",
                credentials: "include",
                headers: {
                    "content-type": "application/json",
                    Authorization: `${customerToken.length > 20 ? `bearer ${customerToken}` : baseUrl.token}`,
                },
                body: JSON.stringify(postData),
            });
            if (res.ok) {
                dispatch(setUpdate());
            }
        } catch (e) {
            console.error("error", e)
        } finally {
            setVisible(false)
        }
    }

    const [expandedRows, setExpandedRows] = useState([]);

    // Toggle expand/collapse for each row
    const toggleRow = (id) => {
        setExpandedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };
    // Check if a row is expanded
    const isRowExpanded = (id) => expandedRows.includes(id);


    if (loading && !cartItems?.length) {
        return <Loader2/>;
    }

    if (!loading && (cartItems?.length <= 0 || cartItems?.length === undefined)) {
        return (
            <SectionWrapper>
                <SectionHeading2 data={data}/>
                <BreadCumb data={navigationData}/>
                <div className="flex items-center justify-center mx-auto mt-3 ">
                    <div>
                        <h4 className="text-center text-base dark:text-white">
                            Cart is empty
                        </h4>
                        <p className="text-xs text-center dark:text-white ">
                            No items added in cart
                        </p>
                        <img
                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-product-8316266-6632286.png"
                            alt=""
                            className="w-20 h-20  ms-3 mt-2"
                        />
                        <Link
                            to="/products"
                            className="mt-2 p-1 border hover:bg-orange-500 hover:text-white text-sm rounded bg-transparent flex items-center justify-center gap-1"
                        >
                            Goto Buy <BsArrowRight className="font-bold"/>
                        </Link>
                    </div>
                </div>
            </SectionWrapper>
        );
    }

    const handleAddOnDataChange = (id, name, value) => {
        const updatedData = addonsList?.map(add_on => {
            if (add_on?.id === id) {
                return {
                    ...add_on,
                    [name]: value
                }
            }
            return add_on
        })
        setAddonsList(updatedData)
    }

    return (
        <section className="">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="bg-white dark:bg-slate-800 p-4 my-3">
                    <div className="grid grid-cols-12 gap-4">
                        {!loading && cartItems?.length > 0 && (
                            <div className=" col-span-12 lg:col-span-9">
                                <SectionHeading2 data={data}/>
                                <BreadCumb data={navigationData}/>
                                <div
                                    style={{minHeight: "53vh"}}
                                    className="mt-6 overflow-scroll lg:overflow-hidden"
                                >
                                    <table className="w-full table-auto">
                                        <thead className="w-full ">
                                        <tr>
                                            <th className="text-left">

                                            </th>
                                            <th className="text-left">
                                              <span className="text-sm lg:text-base dark:text-white text-center">
                                                {" "}
                                                  Product
                                              </span>
                                            </th>
                                            <th className="text-left">
                                              <span className="text-sm lg:text-base dark:text-white text-center  ">
                                                {" "}
                                                  Unit price
                                              </span>
                                            </th>
                                            <th className="text-left">
                                              <span
                                                  className="text-sm lg:text-base dark:text-white text-center ml-2 lg:ml-0">
                                                {" "}
                                                  Quantity
                                              </span>
                                            </th>
                                            <th className="text-left">
                                              <span
                                                  className="text-sm lg:text-base dark:text-white text-center ml-2 lg:ml-0">
                                                {" "}
                                                  Sub-total
                                              </span>
                                            </th>
                                            <th className="text-left">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody className="w-full">
                                        {cartItems?.map((cart, i) => {
                                            const {product_combination, customer_cart_addons} = cart;
                                            const isOnSale = cart?.product_combination?.product?.is_on_sale
                                            const selling_price = cart?.product_combination?.selling_price
                                            let price = isOnSale === 1 && flash_sale ? (selling_price - (selling_price * flash_sale?.discount) / 100) : cart?.product_combination?.selling_price;
                                            let total = cart?.product_quantity * price;
                                            let product_inventory_qty = cart?.product_combination?.inventory_sum_stock_quantity;

                                            return (
                                                <React.Fragment key={i}>
                                                    <tr className="w-full">
                                                        {
                                                            customer_cart_addons.length > 0 ? <td>
                                                                <button onClick={() => toggleRow(cart.id)}>
                                                                    {isRowExpanded(cart.id) ? <IoIosArrowDown/> :
                                                                        <IoIosArrowForward/>}
                                                                </button>
                                                            </td> : <td></td>
                                                        }


                                                        <td className="pl-2 pb-2">
                                                            <div
                                                                // className="flex items-center gap-2"
                                                            >

                                                                {/*<div className="w-5rem">*/}
                                                                {/*    {*/}
                                                                {/*        customer_cart_addons.length > 0 ?*/}
                                                                {/*            <button onClick={() => toggleRow(cart.id)}>*/}
                                                                {/*                {isRowExpanded(cart.id) ?*/}
                                                                {/*                    <IoIosArrowDown/> :*/}
                                                                {/*                    <IoIosArrowForward/>}*/}
                                                                {/*            </button> : ""*/}
                                                                {/*    }*/}
                                                                {/*</div>*/}
                                                                <div
                                                                    className="text-sm text-slate-700  flex-wrap lg:flex dark:text-white text-center">
                                                                    <img
                                                                        className="w-12 h-12 rounded bg-slate-100 text-center"
                                                                        src={`${baseUrl.img}${cart?.product_combination.product?.thumbnail_image}`}
                                                                        alt="cart"
                                                                    />

                                                                    <div>
                                                                        <p className="text-sm lg:text-base dark:text-white lg:ml-2 text-gray-800">
                                                                            {cart?.product_combination?.product?.name?.slice(
                                                                                0,
                                                                                70
                                                                            )}
                                                                        </p>
                                                                        {cart?.product_combination?.attribute_values?.map(
                                                                            (value, j) =>
                                                                                value?.name !== "default" && (
                                                                                    <p
                                                                                        key={j}
                                                                                        className="text-xs font-bold dark:text-white text-left lg:ml-2"
                                                                                    >
                                                                                        {value?.attribute?.name}: {value?.name}
                                                                                    </p>
                                                                                )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td className="text-left pl-2 dark:text-white  ">
                                                            ৳ {price.toFixed(2)}
                                                        </td>
                                                        <td className=" ">
                                                            <div
                                                                className="flex justify-start items-center px-2 lg:px-0">
                                                                <button
                                                                    className="text-center dark:text-white"
                                                                    onClick={() =>
                                                                        updateQuantity(
                                                                            cart?.id,
                                                                            cart?.product_combination?.id,

                                                                            cart?.product_quantity,

                                                                            "decrement",
                                                                            product_inventory_qty
                                                                        )
                                                                    }
                                                                >
                                                                    <FiMinus className=""/>
                                                                </button>
                                                                <button className="text-sm dark:text-white mx-4">
                                                                    {+cart?.product_quantity}
                                                                </button>
                                                                <button
                                                                    className="text-left  dark:text-white"
                                                                    onClick={() =>
                                                                        updateQuantity(
                                                                            cart?.id,
                                                                            cart?.product_combination?.id,

                                                                            cart?.product_quantity,

                                                                            "increment",
                                                                            product_inventory_qty
                                                                        )
                                                                    }
                                                                >
                                                                    <LuPlus className=""/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td className=" pl-2 dark:text-white text-left">
                                                            ৳ {total.toFixed(2)}
                                                        </td>
                                                        <td className="text-center ">
                                                            <div className=" flex gap-2">
                                                                <div className="flex items-center justify-center">
                                                                    <RxCross1
                                                                        className="cursor-pointer text-red-500  ml-5 lg:ml-0 "
                                                                        onClick={() => deleteCart(cart?.id)}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    {
                                                                        customer_cart_addons.length > 0 ?
                                                                            <Button size={"sm"} classes={"p-2"}
                                                                                    func={() => {
                                                                                        setSelectedId(cart?.id)
                                                                                        const modify = product_combination?.product?.add_ons?.map((item2) => {
                                                                                            const selectedItem = customer_cart_addons.find((item1) => item2.id === item1.add_on_id)
                                                                                            if (selectedItem && item2?.id === selectedItem?.add_on_id) {
                                                                                                return {
                                                                                                    ...item2,
                                                                                                    quantity: selectedItem?.quantity,
                                                                                                    selected: true,
                                                                                                }
                                                                                            }
                                                                                            return {
                                                                                                ...item2,
                                                                                                quantity: 1,
                                                                                                selected: false,
                                                                                            }
                                                                                        })
                                                                                        setAddonsList(modify)
                                                                                        setVisible(true)
                                                                                    }}
                                                                                    title={"Update Add ons"}/> : product_combination?.product?.add_ons.length > 0 ?
                                                                                <Button size={"sm"} classes={"p-2"}
                                                                                        func={() => {
                                                                                            setSelectedId(cart?.id)
                                                                                            const filterAddOns = product_combination?.product?.add_ons?.filter(add_on => add_on.status === 1)
                                                                                            const modifiedAddOns = filterAddOns?.map((add_on) => {
                                                                                                const {
                                                                                                    id,
                                                                                                    name,
                                                                                                    price,
                                                                                                    pivot
                                                                                                } = add_on
                                                                                                return {
                                                                                                    id,
                                                                                                    name,
                                                                                                    price,
                                                                                                    product_id: pivot?.product_id,
                                                                                                    selected: false,
                                                                                                    quantity: 1
                                                                                                }
                                                                                            })
                                                                                            setAddonsList(modifiedAddOns)
                                                                                            setVisible(true)
                                                                                        }}
                                                                                        title={"Add ons"}/> : ""

                                                                    }
                                                                </div>
                                                            </div>

                                                        </td>
                                                    </tr>


                                                    {isRowExpanded(cart.id) && (
                                                        <tr>
                                                            <td colSpan="3">
                                                                <div className="py-2 px-3">
                                                                    <p className="text-md font-medium">Addon List</p>

                                                                    {
                                                                        customer_cart_addons.length > 0 ?
                                                                            <div className="pt-1">
                                                                                <table className="w-full ">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th className="text-left">Name</th>
                                                                                        <th className="text-left">Quantity</th>
                                                                                        <th className="text-left">Total</th>
                                                                                    </tr>
                                                                                    </thead>

                                                                                    <tbody>
                                                                                    {
                                                                                        customer_cart_addons?.map((addon, i) => {
                                                                                            const {add_on} = addon
                                                                                            return (
                                                                                                <tr key={i}>
                                                                                                    <th className="text-left">
                                                                                                      <span
                                                                                                          className="text-sm lg:text-base dark:text-white text-center">
                                                                                                          {add_on.name}
                                                                                                      </span>
                                                                                                    </th>
                                                                                                    <th className="text-left">
                                                                                                      <span
                                                                                                          className="text-sm lg:text-base dark:text-white text-center  ">
                                                                                                         {addon.quantity}
                                                                                                      </span>
                                                                                                    </th>
                                                                                                    <th className="text-left">
                                                                                                      <span
                                                                                                          className="text-sm lg:text-base dark:text-white text-center  ">
                                                                                                          {addon.quantity * add_on.price}
                                                                                                      </span>
                                                                                                    </th>
                                                                                                </tr>
                                                                                            )
                                                                                        })

                                                                                    }

                                                                                    {/*<td className="text-left">emon</td>*/}
                                                                                    {/*<td className="text-left">emon</td>*/}
                                                                                    {/*<td className="text-left">emon</td>*/}

                                                                                    </tbody>

                                                                                </table>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                No Addons Found
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </React.Fragment>
                                            );
                                        })}
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        )}

                        {!loading && cartItems?.length > 0 && (
                            <div className="col-span-12 lg:col-span-3">
                                <div className="rounded w-full  lg:ml-auto">
                                    <div
                                        className="bg-white dark:bg-slate-800 rounded-lg mt-0 lg:mt-2 border overflow-hidden">
                                        <div className="bg-gray-50 flex items-center justify-between p-2 border-b">
                                            <h1 className="text-lg font-bold text-slate-700 dark:text-black">
                                                Cart Items
                                            </h1>
                                            <p className="text-slate-500 text-sm dark:text-black">
                                                {cartItems?.length}
                                                <span>{cartItems?.length === 1 ? 'item' : 'items'}</span> added
                                            </p>
                                        </div>
                                        <div className="border-b item-details">
                                            {/* <div className="my-4">
                                                {cartItems?.length &&
                                                  cartItems?.map((cart, i) => {
                                                    let productPrice =
                                                      +cart?.product_quantity *
                                                      cart?.product_combination?.selling_price;
                                                    subTotal += productPrice;
                                                    const isOnSale = cart?.product_combination?.product?.is_on_sale
                                                    const selling_price = cart?.product_combination?.selling_price
                                                    let price = isOnSale === 1 && flash_sale ?( selling_price - (selling_price * flash_sale?.discount) / 100) : cart?.product_combination?.selling_price;
                                                    return (
                                                      <div
                                                        key={i}
                                                        className="flex items-start justify-between gap-2 mb-1 p-2 "
                                                      >
                                                        <div>
                                                          <span className="block font-bold text-base text-gray-800 dark:text-white">
                                                            {i + 1}.{" "}
                                                            {cart?.product_combination?.product?.name}
                                                          </span>
                                                          {cart?.product_combination?.attribute_values?.map(
                                                        (value, j) =>
                                                          value?.name !== "default" && (
                                                            <p
                                                              key={j}
                                                              className="text-xs font-bold dark:text-white text-left text-gray-700"
                                                            >
                                                              {value?.attribute?.name}: {value?.name}
                                                            </p>
                                                          )
                                                      )}
                                                        </div>
                                                        <div className="flex items-center">
                                                          <p className="dark:text-white">৳</p>
                                                          <p className="font-normal dark:text-white">
                                                            { (price *(+cart?.product_quantity)).toFixed(0)}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                              </div> */}

                                            <div className="my-4">
                                                {cartItems?.length &&
                                                    cartItems?.map((cart, i) => {
                                                        const {customer_cart_addons} = cart;
                                                        const isOnSale = cart?.product_combination?.product?.is_on_sale;
                                                        const selling_price = cart?.product_combination?.selling_price;
                                                        const quantity = +cart?.product_quantity;
                                                        const discount = flash_sale?.discount;
                                                        const flashSaleActive = isOnSale === 1 && flash_sale;

                                                        let price = flashSaleActive
                                                            ? selling_price - (selling_price * discount) / 100
                                                            : selling_price;

                                                        // Calculate the individual product total
                                                        const productTotal = price * quantity;

                                                        // Add the individual product total to the subTotal
                                                        subTotal += productTotal;

                                                        return (
                                                            <div key={i} className="p-2">
                                                                <div

                                                                    className="flex items-start justify-between gap-2 mb-1 "
                                                                >
                                                                    <div>
                                                                    <span
                                                                        className="block font-bold text-base text-gray-800 dark:text-white">
                                                                      {i + 1}. {cart?.product_combination?.product?.name}
                                                                    </span>
                                                                        {cart?.product_combination?.attribute_values?.map(
                                                                            (value, j) =>
                                                                                value?.name !== "default" && (
                                                                                    <p
                                                                                        key={j}
                                                                                        className="text-xs font-bold dark:text-white text-left text-gray-700"
                                                                                    >
                                                                                        {value?.attribute?.name}: {value?.name}
                                                                                    </p>
                                                                                )
                                                                        )}
                                                                    </div>

                                                                    <div className="flex items-center gap-1">
                                                                        <p className="dark:text-white">৳</p>
                                                                        <p className="font-normal dark:text-white">
                                                                            {productTotal.toFixed(2)}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <p className="text-base text-gray-800">Addons</p>
                                                                    <div>
                                                                        {
                                                                            customer_cart_addons.map((addon, i) => {
                                                                                const {add_on} = addon;
                                                                                subTotal += add_on?.price * addon.quantity;
                                                                                return (
                                                                                    <div key={i}
                                                                                         className="flex justify-between items-center w-full">
                                                                                        <p>{add_on?.name}</p>
                                                                                        <p>৳ {add_on?.price * addon.quantity}</p>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}


                                            </div>


                                        </div>
                                        <div className="p-3 border-b my-6">
                                            <div className="flex items-center justify-between">
                                                <span className="dark:text-white">Subtotal</span>
                                                <span className="font-bold dark:text-white">
                                                    ৳ {subTotal.toFixed(2)}
                                                </span>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <span className="dark:text-white">Shipping</span>
                                                <span className="font-bold dark:text-white">
                                                  ৳ {shippingCharge}
                                                </span>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <span className="dark:text-white">Tax</span>
                                                <span className="font-bold dark:text-white">
                                              ৳ {tax}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                            <div className="flex items-center justify-between">
                                                <span className="font-bold dark:text-white ">
                                                  Total
                                                </span>
                                                <span className="font-bold dark:text-white">
                                                  ৳ {(subTotal + tax + shippingCharge).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                            <p className="text-sm text-gray-500 dark:text-white">
                                                Your personal data will be used to process your order,
                                                support your experience throughout this website, and for
                                                other purposes described in our privacy policy.
                                            </p>
                                        </div>
                                        <div className="p-3">
                                            <Button size={"md"} classes={"w-full "} func={goCheckout}
                                                    title={"Checkout"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <Rodal
                visible={visible}
                onClose={() => {
                    setVisible(!visible)
                    setAddonsList([])

                }}
                customStyles={{
                    height: "auto",
                    bottom: "auto",
                    width: "40%",
                    top: "40%",
                    transform: "translateY(-50%)",
                }}
            >
                <h3 className="text-lg border-b py-3">Add ons list</h3>

                <div className="space-y-3 pt-4">
                    {
                        addonsList?.map((item, index) => {
                            const {id, name, price, selected, quantity} = item;
                            return (
                                <div key={index} className="flex items-center gap-x-2 ">
                                    <input className="h-5 w-5" type="checkbox" checked={selected} onChange={(e) => {
                                        handleAddOnDataChange(id, "selected", e.target.checked);
                                    }}/>
                                    <div className="flex justify-between items-center gap-x-4 w-full">
                                        <div className="flex items-center justify-between w-full">
                                            <p>{name}</p>
                                            <p>৳ {price * quantity}</p>
                                        </div>
                                        <div className="flex items-center  p-1">
                                            <button
                                                // className="flex items-center justify-center border border-1 border-gray-700 rounded-full  p-1.5 disabled:opacity-70"
                                                className="flex items-center justify-center border border-1 border-gray-700 rounded-full  p-1.5 disabled:opacity-70"
                                                onClick={() => {
                                                    if (quantity > 1) {
                                                        handleAddOnDataChange(id, "quantity", quantity - 1)
                                                    }
                                                }}
                                                // disabled={!selected}
                                            >
                                                <LuMinus/>
                                            </button>
                                            <div className="px-4 text-center font-medium">{quantity}</div>
                                            <button
                                                className="flex items-center justify-center border border-1 border-gray-700 rounded-full p-1.5 "
                                                onClick={() => {
                                                    handleAddOnDataChange(id, "quantity", quantity + 1)
                                                }}

                                            >
                                                <GoPlus/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div>
                        <Button size={"md"} classes={"rounded"} func={handleAddOnsData} title={"Submit"}/>
                    </div>
                </div>

            </Rodal>


            {/*  table */}


        </section>
    );
};

export default CartContainer;
